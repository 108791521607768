import { useEffect, useState } from "react";
import i18next from "i18next";
import ModalsGroup from "./components/layouts/ModalsGroup";
import AppRouter from "./routes/AppRouter";
import en from "./i18n/en";
import es from "./i18n/es";
import esAmericaMovil from "./i18n/esAmericaMovil";
import { I18nextProvider } from "react-i18next";
import useGlobalState from "./store/globalState";
import useAuthState from "./store/authState";
import useComponetState from "./store/components/componentState";
import SessionExpireNotifier from "./components/SessionExpireNotifier";
import useMountedEffect from "./hooks/useMountedEffect";
import useModalState from "./store/modalsState";

i18next.init({
    lng: "es",
    interpolation: {
        escapeValue: false,
    },
    resources: {
        en,
        es,
        esAmericaMovil,
    },
});

function App() {
    const { userCompanyInfo } = useGlobalState();
    const { isLoggedOut, userId } = useAuthState();
    const getUserInfo = useGlobalState((s) => s.getUserInfo);
    const setAdditionalCompanyInfo = useGlobalState((s) => s.setAdditionalCompanyInfo);
    const setKlausChat = useModalState((s) => s.setSagaChatKlaus);
    const getAllTabsPermission = useComponetState ((s) => s.getAllTabs);
    const getAllBtnAdmin = useComponetState ((s) => s.getAllBtnAdmin);
    const getAllBtnProcess = useComponetState ((s) => s.getAllBtnProcess);
    const getAllBtnTemplate = useComponetState ((s) => s.getAllBtnTemplate);
    const checkSessionExpires = useAuthState( s => s.checkSessionExpires )

    useMountedEffect( () => {
        if ( userCompanyInfo ) {
            checkSessionExpires()
        }
    })

    console.log("v3.0.7");
    console.log("___ORKESTADOC__");
    useEffect(() => {
        if (userCompanyInfo && userCompanyInfo?.id) {
            const normalizedCompany = userCompanyInfo?.name
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .replace(/\s/g, "")
                .toLocaleLowerCase();
            if (normalizedCompany === "americamovil" || normalizedCompany === "demoaxeleratum" && localStorage.getItem("lang") === "es") {
                localStorage.removeItem("lang");
                    
                //userCompanyInfo.isAmericaMovil=true;           
            }

            if (normalizedCompany === "klausgermanph&asociados") {
                const typeUserCompany= {
                    isAmericaMovil: false,
                    dashBoardType : 'Klaus'
                }
                setAdditionalCompanyInfo(typeUserCompany);
                setKlausChat(true);
            }

            if (normalizedCompany === "americamovil" || normalizedCompany === "demoaxeleratum") {
                const typeUserCompany= {
                    isAmericaMovil: true,
                    dashBoardType : 'DashV2'
                }
                setAdditionalCompanyInfo(typeUserCompany);
            }

            if (normalizedCompany === "totalplay" || normalizedCompany === "totalbox" || normalizedCompany === "totalplaytelecomunicaciones") {
                const typeUserCompany= {
                    isAmericaMovil: false,
                    dashBoardType : 'DashKivit'
                }
                setAdditionalCompanyInfo(typeUserCompany);
            }
            const newLanguage = localStorage.getItem("lang") ?? (normalizedCompany === "americamovil" || normalizedCompany === "demoaxeleratum" ? "esAmericaMovil" : "es");

            i18next.changeLanguage(newLanguage);
            localStorage.setItem("lang", newLanguage);
        }
    }, [userCompanyInfo]);

    // Initial loads
    useEffect(() => {
        // Get user info
        if (!isLoggedOut) {
            getUserInfo(userId);
            //getAllBtnAdmin();
            //getAllBtnProcess();
        }
    }, [isLoggedOut, getUserInfo, userId]);

    return (
        <>
            <I18nextProvider i18n={i18next}>
                <AppRouter />
                <ModalsGroup />
                { !isLoggedOut && userCompanyInfo && (
                    <SessionExpireNotifier />
                )}
            </I18nextProvider>
        </>
    );
}

export default App;
