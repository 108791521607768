import { configureStore } from '@reduxjs/toolkit'
import leftMenuReducer from './redux/leftMenuSlice'
import authSlice from './redux/authSlice'
import companySlice from './redux/companySlice'
import vaultSlice from './redux/vaultSlice'
import drawerSlice from './redux/drawerSlice'
import chatSlice from './redux/chatSlice'

export default configureStore({
  reducer: {
    leftMenu: leftMenuReducer,
    auth: authSlice,
    company : companySlice,
    vault: vaultSlice,
    drawer: drawerSlice,
    chat: chatSlice,
  },
})