import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";
import useGlobalState from "../../../store/globalState";
import { Tooltip } from "@chakra-ui/react";
import reporte_gri from "src/assets/images/reporte_gri.png"
import s_ambiental from "src/assets/images/s_ambiental.png"
import distintivo_esr from "src/assets/images/distintivo_esr.png"
import compliance from "src/assets/images/compliance.png"
import useModalState from "src/store/modalsState";
import useCustomToast from "src/hooks/useCustomToast";
import useBiddingState from "src/store/biddings/biddingState";

const StaristicsList = () => {
    //const setEditCompanyModal = useModalState((s) => s.setEditCompanyModal);
    const navigate = useNavigate();
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];
    const { getUserCompanyInfo, userInfo } = useGlobalState();
    const additionalCompanyInfo = useGlobalState((s) => s.additionalCompanyInfo);
    const setBiddingTypeModal = useModalState((s) => s.setBiddingTypeModal);
    const toast = useCustomToast();
    const {setFilterByProcess} = useBiddingState((s) => s)

    useEffect(() => {
        if (userInfo.data) {
            getUserCompanyInfo();
        }
    }, [userInfo?.data]);

    const handleGoProcessType = (filter: string) => {
        setFilterByProcess(filter);
        navigate("/biddings");
    };

    const renderShortCuts = () => {
        switch (additionalCompanyInfo.dashBoardType) {
            case 'DashV2':
                return <ShortcutsAmericaMovil />;
            case 'DashKivit':
                return <ShortCutsKivit />;
            default:
                return <ShortcutsAmericaMovil />; 
        }
    };

    
const ShortCutsKivit = () => {
    return null
}

const ShortcutsAmericaMovil = () => {
    return (
        <div>
            <div className="w-full items-center grid grid-cols-4 gap-4">
                <BoxItem 
                    onClick={() => {
                        handleGoProcessType("Sustentabilidad ambiental");
                    }}
                >
                    <div className="flex-auto p-5">
                        <div className="flex items-center justify-between">
                            <img className="w-[28%] mr-3" src={s_ambiental} alt="" />
                            <p className="flex-1 text-0.5xl text-brand-600 leading-8 text-left lg:block hidden" style={{color: '#54A822'}} title="Sustentabilidad ambiental">
                                {"Sustentabilidad ambiental"}
                            </p>
                            <div className="flex-1 text-0.5xl text-brand-600 leading-8 text-left lg:hidden" style={{color: '#54A822'}} data-tip="Sustentabilidad ambiental">
                                <Tooltip label={"Sustentabilidad ambiental"}>...</Tooltip>
                            </div>
                        </div>
                    </div>
                </BoxItem>
                <BoxItem 
                    onClick={() => {
                        handleGoProcessType("Distintivo ESR");
                    }}
                >
                    <div className="flex-auto p-5">
                        <div className="flex items-center justify-between">
                            <img className="w-[30%] mr-3" src={distintivo_esr} alt="" />
                            <p className="flex-1 text-0.5xl text-brand-600 leading-8 text-left lg:block hidden" style={{color: '#54A822'}} title="Distintivo ESR">
                                {"Distintivo ESR"}
                            </p>
                            <div className="flex-1 text-0.5xl text-brand-600 leading-8 text-left lg:hidden" style={{color: '#54A822'}} data-tip="Distintivo ESR">
                                <Tooltip label={"Distintivo ESR"}>...</Tooltip>
                            </div>
                        </div>
                    </div>
                </BoxItem>
                <BoxItem 
                    onClick={() => {
                        handleGoProcessType("Reporte GRI");
                    }}
                >
                    <div className="flex-auto p-5">
                        <div className="flex items-center justify-between">
                            <img className="w-[30%] mr-3" src={reporte_gri} alt="" />
                            <p className="flex-1 text-0.5xl text-brand-600 leading-8 text-left lg:block hidden" style={{color: '#54A822'}} title="Reporte GRI">
                                {"Reporte GRI"}
                            </p>
                            <div className="flex-1 text-0.5xl text-brand-600 leading-8 text-left lg:hidden" style={{color: '#54A822'}} data-tip="Reporte GRI">
                                <Tooltip label={"Reporte GRI"}>...</Tooltip>
                            </div>
                        </div>
                    </div>
                </BoxItem>
                <BoxItem 
                    onClick={() => {
                        handleGoProcessType("Compliance");
                    }}
                >
                    <div className="flex-auto p-5">
                        <div className="flex items-center justify-between">
                            <img className="w-[30%] mr-3" src={compliance} alt="" />
                            <p className="flex-1 text-0.5xl text-brand-600 leading-8 text-left lg:block hidden" style={{color: '#54A822'}} title="Compliance">
                                {"Compliance"}
                            </p>
                            <div className="flex-1 text-0.5xl text-brand-600 leading-8 text-left lg:hidden" style={{color: '#54A822'}} data-tip="Compliance">
                                <Tooltip label={"Compliance"}>...</Tooltip>
                            </div>
                        </div>
                    </div>
                </BoxItem>
            </div>
        </div>
    );
}

    return (
        <>
         {renderShortCuts()}
        </>
    );
};

export default StaristicsList;

const BoxItem = ({ children, onClick }) => {
    return (
        <div
            onClick={onClick}
            className="relative flex border-transparent flex-col min-w-0 break-words bg-white
            transition duration-600 ease-in-out transform hover:scale-105
            shadow-xl dark:bg-slate-850 dark:shadow-dark-xl rounded-2xl bg-clip-border cursor-pointer">
            {children}
        </div>
    );
};
