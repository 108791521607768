import React, { useEffect, useState, useRef } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { styled } from '@mui/material/styles';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import { Box, Slider } from '@mui/material';
import "./RenderPdf.css";

const HighlightedText = styled('span')(({ theme }) => ({
  backgroundColor: 'yellow',
}));

const StyledDocument = styled(Document)(({ theme }) => ({
 
}));

const RenderPdf = ({ base64, pageNumber = 1, highlights = ["Pellentesque sit amet lectus"] }) => {
  const [numPages, setNumPages] = useState(0);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [textHighlights, setTextHighlights] = useState({});
  const [scale, setScale] = useState(1); // Estado para el nivel de zoom
  const [isDragging, setIsDragging] = useState(false);
  const [lastMousePos, setLastMousePos] = useState({ x: 0, y: 0 });
  const scrollContainerRef = useRef(null);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  useEffect(() => {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);
    setPdfUrl(url);

    return () => URL.revokeObjectURL(url);
  }, [base64, pageNumber]);

  useEffect(() => {
    const extractText = async () => {
      const loadingTask = pdfjs.getDocument(URL.createObjectURL(new Blob([new Uint8Array(atob(base64).split('').map(char => char.charCodeAt(0)))])));
      const pdf = await loadingTask.promise;
      const page = await pdf.getPage(pageNumber);
      const textContent = await page.getTextContent();

      const highlightsMap = {};
      textContent.items.forEach(item => {
        highlights.forEach(highlight => {
          if (item.str.includes(highlight)) {
            if (!highlightsMap[pageNumber]) {
              highlightsMap[pageNumber] = [];
            }
            highlightsMap[pageNumber].push({
              text: item.str,
              coords: item.transform,
            });
          }
        });
      });

      setTextHighlights(highlightsMap);
    };

    extractText();
  }, [pdfUrl]);

  const renderTextWithHighlights = (textItems) => {
    return textItems.map((item, index) => (
      <HighlightedText key={index}>
        {item.text}
      </HighlightedText>
    ));
  };

  const handleMouseDown = (event) => {
    setIsDragging(true);
    setLastMousePos({ x: event.clientX, y: event.clientY });
  };

  const handleMouseMove = (event) => {
    if (isDragging && scrollContainerRef.current) {
      const deltaX = event.clientX - lastMousePos.x;
      const deltaY = event.clientY - lastMousePos.y;
      scrollContainerRef.current.scrollLeft -= deltaX;
      scrollContainerRef.current.scrollTop -= deltaY;
      setLastMousePos({ x: event.clientX, y: event.clientY });
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  return (
    <Box
      sx={{
        overflow: 'hidden',
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        userSelect: 'none',
      }}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
    >
      <Box
        ref={scrollContainerRef}
         
        sx={{
          overflow: 'auto',
          flex: 1,
          cursor: isDragging ? 'grabbing' : 'grab',
        }}
        onMouseDown={handleMouseDown}
      >
        
        <StyledDocument file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
          <Page
            scale={scale}
            pageNumber={pageNumber}
            renderTextLayer={true}
            renderAnnotationLayer={false}
          />
        </StyledDocument>
      </Box>
      <Slider
        value={scale}
        min={1}
        max={2}
        step={0.1}
        onChange={(event, newValue) => setScale(newValue)}
        sx={{ width: '200px', margin: '10px auto' }}
      />
      <p>
        Page {pageNumber} of {numPages}
      </p>
      <div className="react-pdf__Page__textContent">
        {textHighlights[pageNumber] && renderTextWithHighlights(textHighlights[pageNumber])}
      </div>
    </Box>
  );
};

export default RenderPdf;