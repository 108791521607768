import { AiOutlineCalendar, AiOutlineQuestionCircle } from "react-icons/ai";
import { Link, NavLink, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";
import NotificationsButtonMenu from "../NotificationsButtonMenu";
import UserButtonMenu from "../UserButtonMenu";
import { HiOutlineVideoCamera } from "react-icons/hi";
import { Tooltip } from "@chakra-ui/react";
import useModalState from "../../store/modalsState";
import useComponetState from "src/store/components/componentState";
import { useState, useEffect, useRef } from "react";
import React from "react";
import useGlobalState from "src/store/globalState";
import { Button, toast, useToast } from "@chakra-ui/react";
import useCustomToast from "../../hooks/useCustomToast";
import useAuthState from "src/store/authState";
import saga_logo from 'src/assets/images/saga_logo.png'
import { useChatHistory } from "src/store/KlausChatState";

const logo = require(`../../assets/images/${process.env.REACT_APP_LOGO}.png`);

const Topbar = ({ sidebarWidth, elRef }: { sidebarWidth: number; elRef?: any }) => {
    const location = useLocation();
    const toast = useCustomToast();
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];
    const setVideoMeetingModal = useModalState((state) => state.setVideoMeetingModal);
    const setFaqModal = useModalState((state) => state.setFaqModal);
    const uiTabsOptions = useComponetState((state) =>  state.uiTabOptions);
    const getAllTabsPermission = useComponetState((s) => s.getAllTabs);
    const { userCompanyInfo } = useGlobalState()
    const tokenExpiresIn = useAuthState((s) => s.tokenExpiresIn);
    const setTokenExpiresIn = useAuthState((s) => s.setTokenExpiresIn);
    const [elapsedTime, setElapsedTime] = useState(0);
    const [timeLoggedIn, setTimeLoggedIn] = useState(0);
    const state = useModalState((s) => s);
    const chatState = useChatHistory((s) => s);

    const showToastRef = useRef(true);
    const tokenRef = useRef(tokenExpiresIn);

    function handleVideoMeeting() {
        setVideoMeetingModal({ show: true });
    }

    function handleShowFaqs() {
        setFaqModal({ show: true });
    }

    useEffect(() => {
        // Get user info
            getAllTabsPermission();
    }, [getAllTabsPermission]);

    

 const formatTime = (seconds: number): string => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    };


    useEffect(() => {
        const startSession = () => {
            const now = Math.floor(Date.now() / 1000); // Tiempo actual en segundos
            localStorage.setItem('loginTime', now.toString());
        };

        const getSessionTime = (): number => {
            const loginTime = localStorage.getItem('loginTime');
            if (loginTime) {
                const now = Math.floor(Date.now() / 1000);
                return now - parseInt(loginTime, 10);
            }
            return 0;
        };

        const updateTime = () => {
            const sessionTime = getSessionTime();
            setTimeLoggedIn(sessionTime);
        };

        // Si no hay tiempo de inicio de sesión guardado, iniciar una nueva sesión
        if (!localStorage.getItem('loginTime')) {
            startSession();
        }

        // Actualizar el tiempo cada segundo
        const interval = setInterval(updateTime, 1000);

        // Actualizar el tiempo inmediatamente al cargar el componente
        updateTime();

        // Limpiar el intervalo al desmontar el componente
        return () => clearInterval(interval);
    }, []);

    return (
        <header ref={elRef} style={{ width: `calc(100% - ${sidebarWidth}rem)` }} className="flex items-center pb-2 pt-3 px-2">
            <Link to="/" className="pr-6 border-r-2 border-gray-300 min-w-[100px] py-1">
                <img className="max-w-[130px] w-full" src={logo} alt="logo" />
            </Link>

            <nav className="flex items-center space-x-10 px-6 font-medium text-base text-base-400 grow">
                <NavLink to="/" className={({ isActive }) => (isActive ? "text-brand-500" : "")}>
                    {t("home")}
                </NavLink>
              
                {!uiTabsOptions.showTabProcess ? ("") : (
                     <NavLink to="/biddings" className={({ isActive }) => (isActive ? "text-brand-500" : "")}>
                        {t("process")}
                    </NavLink>
                )}

                {!uiTabsOptions.showTabContract ? ("") : (
                    <NavLink to="/contracts" className={({ isActive }) => (isActive ? "text-brand-500" : "")}>
                        {t("dynamicDocs")}
                    </NavLink>
                )}

                {!uiTabsOptions.showTabTemplates ? ("") : (
                     <NavLink
                     to="/templates"
                     className={({ isActive }) => (isActive || location?.pathname.startsWith("/data-groups") ? "text-brand-500" : "")}
                 >
                     {t("templates")}
                 </NavLink>
                )}
               
               {!uiTabsOptions.showTabAdmin ? ("") : (
                <NavLink
                    to="/administration"
                    className={({ isActive }) => (isActive || location?.pathname.startsWith("/groups-users") ? "text-brand-500" : "")}
                >
                    {t("administration")}
                </NavLink>
                )}
                
                {/** <NavLink to="/vault" className={({ isActive }) => (isActive ? "text-brand-500" : "")}>
                    {t("vault")}
                </NavLink>*/}
            </nav>
            <div className="flex items-center gap-4">
                <div className="mb-[-.8rem] flex items-center gap-4 mr-2">
                    <h1 className="text-brandOLD-500 text-xs capitalize self-center">{userCompanyInfo?.name}</h1>
                    <NotificationsButtonMenu />
                    {state?.sagaChatKlaus && (
                        <div 
                            style={{cursor: 'pointer', backgroundImage: 'linear-gradient(45deg, #2e8b59 0%, #56bc5b 50%, #232896 100%)', borderRadius: '50%'}} 
                            onClick={() => chatState.changeChatState()}>
                            <img src={saga_logo} />
                        </div>
                    )}
                    {/* <div>
                        <Tooltip label="Videoconferencia">
                            <button onClick={handleVideoMeeting}>
                                <HiOutlineVideoCamera size={36} color="gray" />
                            </button>
                        </Tooltip>
                    </div>
                    <div>
                        <Tooltip label="Calendario">
                            <button>
                                <AiOutlineCalendar size={34} color="gray" />
                            </button>
                        </Tooltip>
                    </div>
                    <div>
                        <Tooltip label="Ayuda">
                            <button onClick={handleShowFaqs}>
                                <AiOutlineQuestionCircle size={34} color="gray" />
                            </button>
                        </Tooltip>
                    </div>*/}
                </div>
                <UserButtonMenu />
      {/* <p>Tiempo transcurrido: {formatTime(timeLoggedIn)}</p>*/}
            </div>
        </header>
    );
};

export default React.memo(Topbar);
