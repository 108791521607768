import RestApi from './restApi';

class CompanyService {
    constructor() {
        this.api = new RestApi();
    }

    async getCompanies(page = 0, rfc = '', search = '', size = 10) {
        try {
            const response = await this.api.get(`companies?page=${page}&rfc=${rfc}&search=${search}&size=${size}`);
            return response;
        } catch (error) {
            throw error;
        }
    }

    async createCompany(companyDTO) {
        try {
            const response = await this.api.post('companies', companyDTO);
            return response;
        } catch (error) {
            throw error;
        }
    }

    async updateCompany(companyDTO) {
        try {
            const response = await this.api.put('companies', companyDTO);
            return response;
        } catch (error) {
            throw error;
        }
    }

    async deleteCompany(companyId) {
        try {
            const response = await this.api.delete(`companies/${companyId}`);
            return response;
        } catch (error) {
            throw error;
        }
    }

    async getCompanyById(companyId) {
        try {
            const response = await this.api.get(`companies/${companyId}`);
            return response;
        } catch (error) {
            throw error;
        }
    }

    async getCompaniesOwner() {
        try {
            const response = await this.api.get('companies/owner');
            return response;
        } catch (error) {
            throw error;
        }
    }

    async createGroup(companyDTO) {
        try {
            const response = await this.api.post('companies/groups/create', companyDTO);
            return response;
        } catch (error) {
            throw error;
        }
    }

    async createAndSetOwner(companyDTO) {
        try {
            const response = await this.api.post('companies/init', companyDTO);
            return response;
        } catch (error) {
            throw error;
        }
    }

    async getAllRfcCompanies() {
        try {
            const response = await this.api.get('companies/rfc');
            return response;
        } catch (error) {
            throw error;
        }
    }

    async updateFullBlackList() {
        try {
            const response = await this.api.get('companies/update/blackList');
            return response;
        } catch (error) {
            throw error;
        }
    }

    async updateBlackList(companyId, status) {
        try {
            const response = await this.api.put(`companies/${companyId}/blackList`, { status });
            return response;
        } catch (error) {
            throw error;
        }
    }

    async activateCompany(companyId) {
        try {
            const response = await this.api.patch(`companies/${companyId}/enable`, {});
            return response;
        } catch (error) {
            throw error;
        }
    }

    async updateProfilePicture(companyId, pictureData) {
        try {
            const response = await this.api.post(`companies/${companyId}/profilepicture`, pictureData);
            return response;
        } catch (error) {
            throw error;
        }
    }

    async readPublicCompany(companyId) {
        try {
            const response = await this.api.get(`companies/${companyId}/public`);
            return response;
        } catch (error) {
            throw error;
        }
    }

    async getUsersByCompanyId(companyId) {
        try {
            const response = await this.api.get(`companies/${companyId}/users`);
            return response;
        } catch (error) {
            throw error;
        }
    }

    async removeUserFromCompany(companyId, userId) {
        try {
            const response = await this.api.delete(`companies/${companyId}/users/${userId}`);
            return response;
        } catch (error) {
            throw error;
        }
    }

    async addUserToCompany(companyId, userId) {
        try {
            const response = await this.api.post(`companies/${companyId}/users/${userId}`, {});
            return response;
        } catch (error) {
            throw error;
        }
    }

    async addMembersToGroup(groupId, memberList) {
        try {
            const response = await this.api.post(`companies/groups/${groupId}/members`, memberList);
            return response;
        } catch (error) {
            throw error;
        }
    }

    async removeMembersFromGroup(groupId, memberIdList) {
        try {
            const response = await this.api.delete(`companies/groups/${groupId}/members`, memberIdList);
            return response;
        } catch (error) {
            throw error;
        }
    }

}

const companyService = new CompanyService();

export default companyService;