import { Bidding, BiddingLite, CreateBiddingBody } from "src/models/biddings/Bidding.models";
import biddingService from "src/services/biddings/bidding.service";
import create, { GetState } from "zustand";
import { NamedSet } from "zustand/middleware";
import useAuthState from "../authState";
import useModalState from "../modalsState";

interface State {
    biddings: { loading: boolean; error: boolean; data: BiddingLite[] };
    filterByProcess: string;
    selectedBidding: Bidding;
    dataFileAmericaMovil: any;
    currentBiddingCreated: any;
    movilData: any;
    movilDataImg: any;
    setSelectedBidding: (bidding: Bidding) => void;
    setFilterByProcess: (filter: string) => void;
    getBiddings: (withLoading?: boolean) => Promise<boolean>;
    getBidding: (biddingId: string, withLoading?: boolean) => Promise<Bidding | null>;
    createBidding: (bidding: CreateBiddingBody, withLoading?: boolean) => Promise<boolean>;
    createBiddingReturnDataResponse: (bidding: CreateBiddingBody, withLoading?: boolean) => Promise<any>;
    updateBidding: (bidding: Bidding, withLoading?: boolean) => Promise<boolean>;
    transformStringPattern: (pattern: any, withLoading?: boolean) => Promise<any>;
    transformStringPatternForProperties: (pattern: any, withLoading?: boolean) => Promise<any>;
    deleteBidding: (biddingId: string, withLoading?: boolean) => Promise<boolean>;
    addBiddingRequirementDocument: (
        body: any,
        biddingId: string,
        reqId: string,
        withLoading?: boolean
    ) => Promise<{ uploaded: boolean; fileExists?: boolean; response?: string }>;
    editBiddingRequirementDocument: (
        body: any,
        biddingId: string,
        reqId: string,
        withLoading?: boolean
    ) => Promise<{ uploaded: boolean; fileExists?: boolean; response?: string }>;
    addDocumentToSession: (body: any, withLoading?: boolean) => Promise<{ uploaded: boolean; fileExists?: boolean; response?: string }>;
    addBiddingRequirementAnnex: (
        body: any,
        biddingId: string,
        reqId: string,
        withLoading?: boolean
    ) => Promise<{ uploaded: boolean; fileExists?: boolean; response?: string }>;
    removeBiddingReqDocumentOrAnnex: (docUid: string, biddingId: string, reqId: string, withLoading?: boolean) => Promise<boolean>;
    enableBiddingReq: (biddingId: string, reqId: string, withLoading?: boolean) => Promise<any>;
    addSmartContractToBiddingReq: (body: any, biddingId: string, reqId: string, withLoading?: boolean) => Promise<any>;
    desassociateSmartContractToBiddingReq: (docUid: string, biddingId: string, reqId: string, withLoading?: boolean) => Promise<boolean>;
    updateExpedient: (biddingId: string, dataGroups: Bidding["expedienteUnico"], withLoading?: boolean) => Promise<boolean>;
    setDataFileAmericaMovil : (dataFileAmericaMovil : any , withLoading?: boolean) => any;
    getMovilChekData :  (withLoading?: boolean) => Promise<any>;
    getMovilImgData :  (withLoading?: boolean) => Promise<any>;
    reset: () => void;
}

const initialState = {
    biddings: { loading: false, error: false, data: [] },
    selectedBidding: null,
    dataFileAmericaMovil: null,
    currentBiddingCreated: null,
    movilData: null,
    movilDataImg: null,
    filterByProcess: null,
};

const useBiddingState = create<State>((set, get) => ({
    ...initialState,
    setSelectedBidding: (bidding: Bidding) => set({ selectedBidding: bidding }),
    setFilterByProcess: (filter: string) => set({filterByProcess: filter}),
    getBiddings: getBiddings(set, get),
    getBidding: getBidding(set, get),
    createBidding: createBidding(set, get),
    createBiddingReturnDataResponse : createBiddingReturnDataResponse(set, get),
    updateBidding: updateBidding(set, get),
    transformStringPattern: transformStringPattern(set, get),
    transformStringPatternForProperties: transformStringPatternForProperties(set, get),
    deleteBidding: deleteBidding(set, get),
    addBiddingRequirementDocument: addBiddingRequirementDocument(set, get),
    editBiddingRequirementDocument: editBiddingRequirementDocument(set, get),
    addDocumentToSession: addDocumentToSession(set, get),
    addBiddingRequirementAnnex: addBiddingRequirementAnnex(set, get),
    enableBiddingReq: enableBiddingReq(set, get),
    addSmartContractToBiddingReq: addSmartContractToBiddingReq(set, get),
    removeBiddingReqDocumentOrAnnex: removeBiddingReqDocumentOrAnnex(set, get),
    desassociateSmartContractToBiddingReq: desassociateSmartContractToBiddingReq(set, get),
    updateExpedient: updateExpedient(set, get),
    setDataFileAmericaMovil : setDataFileAmericaMovil (set, get),
    getMovilChekData : getMovilChekData (set, get),
    getMovilImgData : getMovilImgData (set, get),
    reset: () => set((state) => ({ ...state, ...initialState })),
}));

export default useBiddingState;

function hasPermissionForAction(reponse: any, msgPermission: string, redirect: boolean) {
    if (reponse?.data?.codigo === 3 && reponse?.data?.mensaje === "No cuenta con el permiso para esta acción.") {
        useModalState.getState().setRestrictedActionModal({
            show: true,
            data: {
                text1: "AVISO",
                text2: msgPermission,
                text3: "Lo sentimos, no cuenta con los permisos necesarios para realizar esta acción. Por favor, contacte a su administrador para configurar sus permisos.",
                redirectHome: redirect,
                onClose: async () => {
                    return { show: false };
                },
            },
        });
    }
}

//FUNCTIONS

function getMovilChekData(set: NamedSet<State>, get: GetState<State>) {
    return async (withLoading = true) => {
        const idCompany = useAuthState.getState().companyId;

        withLoading && useModalState.getState().setLoaderModal(true);
        const res = await biddingService.getDataMovil();
      //  hasPermissionForAction(res, "Listar procesos", true);

        withLoading && useModalState.getState().setLoaderModal(false);
        if (res?.data?.codigo === 0) {
            console.log("res movil data",res?.data.respuesta)
            set({ movilData:  res.data.respuesta })

            return true;
        } else {
            console.log("Error fetching biddings");
            throw new Error("Error fetching biddings");
        }
    };
}

function getMovilImgData(set: NamedSet<State>, get: GetState<State>) {
    return async (withLoading = true) => {
        const idCompany = useAuthState.getState().companyId;

        withLoading && useModalState.getState().setLoaderModal(true);
        const res = await biddingService.getDataMovilImg();
      //  hasPermissionForAction(res, "Listar procesos", true);

        withLoading && useModalState.getState().setLoaderModal(false);
        if (res?.data?.codigo === 0) {
            console.log("res movil data",res?.data)
            set({ movilDataImg:  res.data.respuesta })
            return true;
        } else {
            console.log("Error fetching biddings");
            throw new Error("Error fetching biddings");
        }
    };
}

function getBiddings(set: NamedSet<State>, get: GetState<State>) {
    return async (withLoading = true) => {
        const idCompany = useAuthState.getState().companyId;

        withLoading && useModalState.getState().setLoaderModal(true);
        const res = await biddingService.getBiddingsByCompany(idCompany);
        hasPermissionForAction(res, "Listar procesos", true);

        withLoading && useModalState.getState().setLoaderModal(false);
        if (res?.data?.codigo === 0) {
            if (useBiddingState.getState().filterByProcess === null) {
                set({ biddings: { loading: false, error: false, data: res.data.respuesta.reverse() } });
            } else {
                const filterValue = useBiddingState.getState().filterByProcess.toLowerCase();
                const filteredData = res.data.respuesta.reverse().filter(bid => 
                    bid.biddingTypeName.toLowerCase().includes(filterValue) ||
                    bid.name.toLowerCase().includes(filterValue) // Añadido el filtro por 'name'
                );
                set({ biddings: { loading: false, error: false, data: filteredData } });
            }            
            set({ filterByProcess: null });  
            return true;
        } else {
            console.log("Error fetching biddings");
            throw new Error("Error fetching biddings");
        }
    };
}

function getBidding(set: NamedSet<State>, get: GetState<State>) {
    return async (biddingId: string, withLoading = true) => {
        withLoading && useModalState.getState().setLoaderModal(true);
        const res = await biddingService.getBidding(biddingId);

        hasPermissionForAction(res, "Obtener proceso", true);

        withLoading && useModalState.getState().setLoaderModal(false);

        /*if(res.data.respuesta.companyOwnerId !== "646b96b845429d561922a90"){
            res.data.respuesta.stages = file.stages
        }}*/
        if (res?.data?.codigo === 0) {
            set({ selectedBidding: res.data.respuesta });
            return res.data.respuesta;
        } else {
            console.log("Error fetching bidding");
            set({ selectedBidding: null });
            return null;
        }
    };
}

function createBidding(set: NamedSet<State>, get: GetState<State>) {
    return async (body: CreateBiddingBody, withLoading = true) => {
        withLoading && useModalState.getState().setLoaderModal(true);

        const res = await biddingService.createBidding(body);
        hasPermissionForAction(res, "Crear proceso", false);

        withLoading && useModalState.getState().setLoaderModal(false);
        if (res?.data?.codigo === 0) {
            return true;
        } else {
            console.log("Error saving bidding");
            return false;
        }
    };
}

function createBiddingReturnDataResponse(set: NamedSet<State>, get: GetState<State>) {
    return async (body: CreateBiddingBody, withLoading = true) => {
        withLoading && useModalState.getState().setLoaderModal(true);

        const res = await biddingService.createBidding(body);
        hasPermissionForAction(res, "Crear proceso", false);

        withLoading && useModalState.getState().setLoaderModal(false);
        if (res?.data?.codigo === 0) {
            let response = res.data.respuesta;
            console.log("reess res", response)
            set({ currentBiddingCreated: response });

            return res?.data;
        } else {
            console.log("Error saving bidding");
            return false;
        }
    };
}

function updateBidding(set: NamedSet<State>, get: GetState<State>) {
    return async (bidding: Bidding, withLoading = true) => {
        withLoading && useModalState.getState().setLoaderModal(true);

        const res = await biddingService.updateBidding(bidding);

        hasPermissionForAction(res, "Actualizar proceso", false);

        withLoading && useModalState.getState().setLoaderModal(false);
        if (res?.data?.codigo === 0) {
            return true;
        } else {
            console.log("Error updating bidding");
            return false;
        }
    };
}

function transformStringPatternForProperties(set: NamedSet<State>, get: GetState<State>) {
    return async (pattern: any, withLoading = true) => {
        withLoading && useModalState.getState().setLoaderModal(true);

        const res = await biddingService.transformPatternBidding(pattern);

        withLoading && useModalState.getState().setLoaderModal(false);

        if (res?.data?.codigo === 0) {
            return res.data;
        } else {
            console.log("Error deleting bidding");
            return false;
        }
    };
}

function transformStringPattern(set: NamedSet<State>, get: GetState<State>) {
    return async (pattern: any, withLoading = true) => {
        withLoading && useModalState.getState().setLoaderModal(true);

        try {
            const biddingList = get().biddings.data;
            const res = await biddingService.transformPatternBidding(pattern);
            const originalText = res.data.respuesta;

            const patternWithoutBrackets = originalText.replace(/\[|\]/g, "");
            const hasVariableZeros = /\[\d+0+\]/.test(pattern.stringWithPattern);

            if (hasVariableZeros) {
                const numberOfZeros = (pattern.stringWithPattern.match(/\[0+\]/)?.[0].match(/0/g) || []).length || 1;

                const baseName = patternWithoutBrackets.replace(/-\d+$/, "");
                let nextSerialNumber = 1;

                while (
                    biddingList.some((name) => name.name === `${baseName}-${nextSerialNumber.toString().padStart(numberOfZeros, "0")}`)
                ) {
                    nextSerialNumber++;
                }

                return `${baseName}-${nextSerialNumber.toString().padStart(numberOfZeros, "0")}`;
            } else {
                return originalText;
            }
        } catch (error) {
            console.error("Error generating pattern:", error);
            return false;
        } finally {
            withLoading && useModalState.getState().setLoaderModal(false);
        }
    };
}

function transformStringPattern2(set: NamedSet<State>, get: GetState<State>) {
    return async (pattern: any, withLoading = true) => {
        withLoading && useModalState.getState().setLoaderModal(true);
        const biddingList = get().biddings.data;

        try {
            const res = await biddingService.transformPatternBidding(pattern);
            const originalText = res.data.respuesta;

            const patternWithoutBrackets = originalText.replace(/\[|\]/g, "");
            const hasVariableZeros = /\[\d+0+\]/.test(pattern.stringWithPattern);

            const numberOfZeros = (pattern.stringWithPattern.match(/\[0+\]/)?.[0].match(/0/g) || []).length || 1;

            if (hasVariableZeros) {
                const patternWithoutLastZero = originalText.replace(/\[(0+)(0)\]$/, "[$1]1");
                const patternWithoutBrackets = patternWithoutLastZero.replace(/\[|\]/g, "");

                console.log("---ññp ", patternWithoutBrackets);

                const matchingNames = biddingList.filter((c) => c.name.includes(patternWithoutBrackets));
                console.log("matchingNames", matchingNames);

                if (matchingNames.length === 0) {
                    // Si no hay coincidencias, simplemente devuelve el patrón sin modificaciones
                    return patternWithoutBrackets;
                }

                const getNextSerialNumber = (baseNumber: number) => {
                    let nextNumber = baseNumber;
                    while (
                        biddingList.some((name) => name.name === patternWithoutBrackets.replace(/\[(0+)(0)\]$/, `[$1]${nextNumber}`)) &&
                        nextNumber < 100
                    ) {
                        // Agrega una condición de límite para evitar bucles infinitos
                        nextNumber++;
                    }
                    return nextNumber;
                };

                const maxSerialNumber = Math.max(
                    ...matchingNames.map((name) => {
                        const matchResult = (name as { name: string }).name.match(/\d+$/);
                        return matchResult ? parseInt(matchResult[0], 10) : 0;
                    })
                );

                const nextSerialNumber = getNextSerialNumber(maxSerialNumber + 1);

                let biddingTypeName = patternWithoutBrackets.replace(/\[(0+)(0)\]$/, `[$1]${nextSerialNumber}`);
                console.log("000 number", biddingTypeName);

                return biddingTypeName;
            } else {
                return originalText;
            }
        } catch (error) {
            console.error("Error generating pattern:", error);
            return false;
        } finally {
            // Asegúrate de ejecutar setLoaderModal(false) en caso de error o éxito
            withLoading && useModalState.getState().setLoaderModal(false);
        }
    };
}

function transformStringPattern3(set: NamedSet<State>, get: GetState<State>) {
    return async (pattern: any, withLoading = true) => {
        withLoading && useModalState.getState().setLoaderModal(true);
        const biddingList = get().biddings.data;

        const res = await biddingService.transformPatternBidding(pattern);

        try {
            const originalText: string = String(res.data.respuesta);

            if (typeof originalText !== "string") {
                console.log("El contenido de respuesta no es una cadena de texto.");
                return;
            }

            const patronRegex = /\[([0-9]+)\]/;
            const match = patronRegex.exec(originalText);

            if (!match) {
                console.log("El patrón no contiene corchetes con números.");
                return;
            }

            const numeroCorchetes = parseInt(match[1]);

            // Manejar el caso donde el último cero se reemplaza correctamente
            const patternWithoutLastZero = originalText.replace(/\[(0+)(0)\]$/, "[$1]1");
            const patternWithoutBrackets = patternWithoutLastZero.replace(/\[|\]/g, "");

            const existingNumbers = biddingList
                .filter((bidding) => {
                    const nombreMatch = bidding.name?.match(/\[([0-9]+)\]/);
                    return nombreMatch && parseInt(nombreMatch[1]) === numeroCorchetes;
                })
                .map((bidding) => {
                    const nombreMatch = bidding.name?.match(/\[([0-9]+)\]/);
                    return nombreMatch ? parseInt(nombreMatch[1]) : 0;
                });

            let nextNumber = Math.max(...existingNumbers, 0) + 1;
            let newUniqueName = patternWithoutBrackets.replace(
                `[${numeroCorchetes.toString().padStart(2, "0")}]`,
                `${nextNumber.toString().padStart(2, "0")}`
            );

            // Verificar si el nuevo nombre ya existe, y si es así, incrementar el número hasta que sea único
            while (biddingList.some((bidding) => bidding.name === newUniqueName)) {
                nextNumber++;
                newUniqueName = patternWithoutBrackets.replace(
                    `[${numeroCorchetes.toString().padStart(2, "0")}]`,
                    `${nextNumber.toString().padStart(2, "0")}`
                );
            }

            console.log("Next Unique Name:", newUniqueName); // Log para propósitos de depuración

            return newUniqueName;
        } catch (error) {
            console.error("Error generating pattern:", error);
            return false;
        } finally {
            // Asegúrate de ejecutar setLoaderModal(false) en caso de error o éxito
            withLoading && useModalState.getState().setLoaderModal(false);
        }
    };
}

function deleteBidding(set: NamedSet<State>, get: GetState<State>) {
    return async (biddingId: string, withLoading = true) => {
        withLoading && useModalState.getState().setLoaderModal(true);

        const res = await biddingService.deleteBidding(biddingId);

        hasPermissionForAction(res, "Eliminar proceso", false);

        withLoading && useModalState.getState().setLoaderModal(false);
        if (res?.data?.codigo === 0) {
            set((state) => ({
                biddings: { ...state.biddings, data: state.biddings.data?.filter((temp) => temp.id !== biddingId) },
            }));
            return true;
        } else {
            console.log("Error deleting bidding");
            return false;
        }
    };
}
function addBiddingRequirementDocument(set: NamedSet<State>, get: GetState<State>) {
    return async (body: any, biddingId: string, reqId: string, withLoading = true) => {
        withLoading && useModalState.getState().setLoaderModal(true);
        const res = await biddingService.addDocumentToBiddingReq(biddingId, reqId, body);

        hasPermissionForAction(res, "Subir documento ", false);

        withLoading && useModalState.getState().setLoaderModal(false);

        if (res?.data?.codigo === 0) {
            return { uploaded: true, fileExists: null };
        } else {
            console.log("Error adding bidding requirement document");
            return { uploaded: false, fileExists: res?.response?.data?.mensaje?.includes("ya existe") };
        }
    };
}

function editBiddingRequirementDocument(set: NamedSet<State>, get: GetState<State>) {
    return async (body: any, biddingId: string, reqId: string, withLoading = true) => {
        withLoading && useModalState.getState().setLoaderModal(true);
        const res = await biddingService.edditDocumentToBiddingReq(biddingId, reqId, body);

        hasPermissionForAction(res, "Editar documento ", false);

        withLoading && useModalState.getState().setLoaderModal(false);

        if (res?.data?.codigo === 0) {
            return { uploaded: true, fileExists: null };
        } else {
            console.log("Error editing bidding requirement document");
            return { uploaded: false, fileExists: res?.response?.data?.mensaje?.includes("ocurrio un error") };
        }
    };
}

function addDocumentToSession(set: NamedSet<State>, get: GetState<State>) {
    return async (body: any, withLoading = true) => {
        withLoading && useModalState.getState().setLoaderModal(true);
        const res = await biddingService.addDocumentToSession(body.file);

        hasPermissionForAction(res, "Subir documento ", false);

        withLoading && useModalState.getState().setLoaderModal(false);
        if (res?.data?.codigo === 0) {
            sessionStorage.setItem("sessionId", res.data.respuesta);
            return { uploaded: true, fileExists: null, response: res?.data?.respuesta };
        } else {
            console.log("Error adding bidding requirement document");
            return { uploaded: false, fileExists: res?.response?.data?.mensaje?.includes("ya existe"), response: null };
        }
    };
}

function addBiddingRequirementAnnex(set: NamedSet<State>, get: GetState<State>) {
    return async (body: any, biddingId: string, reqId: string, withLoading = true) => {
        withLoading && useModalState.getState().setLoaderModal(true);
        const res = await biddingService.addAnnexToBiddingReq(biddingId, reqId, body);
        hasPermissionForAction(res, "Subir anexo ", false);

        withLoading && useModalState.getState().setLoaderModal(false);

        if (res?.data?.codigo === 0) {
            return { uploaded: true, fileExists: null };
        } else {
            console.log("Error adding bidding requirement annex");
            return { uploaded: false, fileExists: res?.response?.data?.mensaje?.includes("ya existe") };
        }
    };
}

function removeBiddingReqDocumentOrAnnex(set: NamedSet<State>, get: GetState<State>) {
    return async (biddingId: string, reqId: string, docId: string, withLoading = true) => {
        withLoading && useModalState.getState().setLoaderModal(true);
        const res = await biddingService.removeDocumentOrAnnexe(biddingId, reqId, docId);

        hasPermissionForAction(res, "Remover documento ", false);

        withLoading && useModalState.getState().setLoaderModal(false);

        if (res?.data?.codigo === 0) {
            return true;
        } else {
            console.log("Error removing bidding requirement document");
            return false;
        }
    };
}

function enableBiddingReq(set: NamedSet<State>, get: GetState<State>) {
    return async (biddingId: string, reqId: string, withLoading = true) => {
        withLoading && useModalState.getState().setLoaderModal(true);
        const res = await biddingService.enableBiddingReq(biddingId, reqId);

        hasPermissionForAction(res, "Habilitar requerimiento", false);

        withLoading && useModalState.getState().setLoaderModal(false);
        if (res?.data?.codigo === 0) {
            return {
                success: res?.data?.codigo === 0 ? true : false,
                res: res?.data?.respuesta,
            };
        } else {
            console.log("Error enabling bidding requirement");
            return obtenerMensajeDeError(res.mensaje);
        }
    };
}

function addSmartContractToBiddingReq(set: NamedSet<State>, get: GetState<State>) {
    return async (body: any, biddingId: string, reqId: string, withLoading = true) => {
        withLoading && useModalState.getState().setLoaderModal(true);
        const res = await biddingService.addSmartContractToBiddingReq(biddingId, reqId, body);

        hasPermissionForAction(res, "Asignar documento dinámico", false);

        withLoading && useModalState.getState().setLoaderModal(false);
        if (res?.data?.codigo === 0) {
            return true;
        } else {
            console.log("Error adding smart contract to bidding requirement");
            return obtenerMensajeDeError(res.mensaje);
        }
    };
}

function updateExpedient(set: NamedSet<State>, get: GetState<State>) {
    return async (biddingId: string, dataGroups: Bidding["expedienteUnico"], withLoading = true) => {
        withLoading && useModalState.getState().setLoaderModal(true);

        const res = await biddingService.updateExpedient(biddingId, { expedienteUnico: dataGroups });
        hasPermissionForAction(res, "Actualizar expediente ", false);

        withLoading && useModalState.getState().setLoaderModal(false);
        if (res?.data?.codigo === 0) {
            return true;
        } else {
            console.log("Error updating expedient");
            return false;
        }
    };
}

function desassociateSmartContractToBiddingReq(set: NamedSet<State>, get: GetState<State>) {
    return async (docIud: string, biddingId: string, reqId: string, withLoading = true) => {
        withLoading && useModalState.getState().setLoaderModal(true);
        const res = await biddingService.removeSmartContractToBiddingReq(biddingId, reqId, docIud);
        hasPermissionForAction(res, "Desasociar documento dinámico", false);

        withLoading && useModalState.getState().setLoaderModal(false);

        if (res?.data?.codigo === 0) {
            return true;
        } else {
            console.log("Error desassociating smart contract to bidding requirement");
            return false;
        }
    };
}

function setDataFileAmericaMovil(set: NamedSet<State>, get: GetState<State>) {
    return async (dataFile) => {
            set({ dataFileAmericaMovil: dataFile })
    };
}
const obtenerMensajeDeError = (mensajeCompleto: string): string => {
    const regex = /Exception: \|([^|]+)\|/;
    const match = mensajeCompleto.match(regex);

    return match ? match[1].trim() : mensajeCompleto;
};

