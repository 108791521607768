import useModalState from "../../store/modalsState";
import ConfirmAlertModal from "../common/modals/ConfirmAlertModal";
import ContractRevisionModal from "../common/modals/contract/ContractRevisionModal";
import ContractSignaturesModal from "../common/modals/contract/ContractSignaturesModal";
import ContractTypesModal from "../common/modals/contract/ContractTypesModal";
import DataGroupSelectModal from "../common/modals/data-group/DataGroupSelectModal";
import DeleteCommonModal from "../common/modals/DeleteCommonModal";
import DeleteTemplateModal from "../common/modals/template/DeleteTemplateModal";
import EditCompanyModal from "../common/modals/EditCompanyModal";
import EditUserModal from "../common/modals/EditUserModal";
import LoaderModal from "../common/modals/LoaderModal";
import NewDataTypeModal from "../common/modals/NewDataTypeModal";
import NewVaultFolderModal from "../common/modals/vault/NewVaultFolderModal";
import PdfPreviewModal from "../common/modals/PdfPreviewModal";
import TemplateSelectModal from "../common/modals/template/TemplateSelectModal";
import TokenIlustrationModal from "../common/modals/contract/TokenIlustrationModal";
import UploadVaultDocumentModal from "../common/modals/vault/UploadVaultDocumentModal";
import NewEditUserModal from "../common/modals/administration/NewEditUserModal";
import NewEditUsersGroupModal from "../common/modals/administration/NewEditUsersGroupModal";
import UploadContractAnnexModal from "../common/modals/contract/UploadContractAnnexModal";
import SimpleAlertModal from "../common/modals/SimpleAlertModal";
import ContractBulkLoad from "../common/modals/template/ContractBulkLoad";
import TemplateHeaderFooterModal from "../common/modals/template/TemplateHeaderFooterModal";
import VideoMeetingModal from "../common/modals/VideoMeetingModal";
import TemplateFromDocxModal from "../common/modals/template/TemplateFromDocxModal";
import FAQModal from "../common/modals/FAQModal";
import UploadBiddingDocModal from "../common/modals/biddings/UploadBiddingDocModal";
import NewBiddingModal from "../common/modals/biddings/NewBiddingModal";
import BiddingTypeModal from "../common/modals/biddings/BiddingTypeModal";
import ExpedientModal from "../common/modals/biddings/ExpedientModal";
import CreateExpedienteModal from "../common/modals/biddings/CreateExpedientModal";
import HandleBiddingSCMModal from "../common/modals/biddings/HandleBiddingSCMModal";
import AddNewCompanyModal from "../common/modals/administration/AddNewCompanyModal";
import CreateNewCompanyModal from "../common/modals/administration/CreateNewCompanyModal";
import NewEditRoleModal from "../common/modals/administration/NewEditRoleModal";
import BiddingTypeUrlPowerBI from "../common/modals/biddings/BiddingTypeUrlPowerBI";
import ShowBiddingPowerBiModal from "../common/modals/biddings/ShowBiddingPowerBiModal";
import BiddingProfileModal from "../common/modals/biddings/BiddinProfileModal";
import BiddingNotificationsModal from "../common/modals/biddings/BiddinNotificationsModal";
import BiddingTypeNotificationsModal from "../common/modals/biddings/BiddinTypeNotificationsModal";
import InfoCommonModal from "../common/modals/InfoCommonModal";
import RestrictedActionModal from "../common/modals/RestrictedActionModal";
import TraceabilityCommonDrawer from "../common/modals/TraceabilityCommonDrawer";
import ShowUsersOtpModal from "../common/modals/administration/ShowUsersOtpModal";
import UploadBiddingAxCalcModal from "../common/modals/biddings/UploadBiddingAxCalcModal";
import GeneradorStateModal from "../common/modals/biddings/Generadores/GeneradorStateModal";
import KlausChat from "../common/modals/KlausChat/KlausChatBubble";

const ModalsGroup = () => {
    const state = useModalState((s) => s);

    //keep showing with conditional for well work of useEffects inside the modals
    return (
        <>
            {state?.editUserModal.show && (
                <EditUserModal
                    isOpen={true}
                    onClose={() => state?.setEditUserModal({ show: false, data: null })}
                    data={state?.editUserModal.data}
                />
            )}
            {state?.editCompanyModal.show && (
                <EditCompanyModal
                    isOpen={true}
                    onClose={() => state?.setEditCompanyModal({ show: false, data: null })}
                    data={state?.editCompanyModal.data}
                />
            )}
            {state?.deleteTemplateModal.show && (
                <DeleteTemplateModal
                    isOpen={true}
                    onClose={() => state?.setDeleteTemplateModal({ show: false, data: null })}
                    data={state?.deleteTemplateModal.data}
                />
            )}
            {state?.newDataTypeModal.show && (
                <NewDataTypeModal
                    isOpen={true}
                    onClose={() => state?.setNewDatatTypeModal({ show: false, data: null })}
                    data={state?.newDataTypeModal.data}
                />
            )}
            {state?.contractTypesModal.show && (
                <ContractTypesModal
                    isOpen={true}
                    onClose={() => state?.setContractTypesModal({ show: false, data: null })}
                    data={state?.contractTypesModal.data}
                />
            )}
            {state?.templateSelectModal.show && (
                <TemplateSelectModal
                    isOpen={true}
                    onClose={() => state?.setTemplateSelectModal({ show: false, data: null })}
                    data={state?.templateSelectModal.data}
                />
            )}
            {state?.dataGroupSelectModal.show && (
                <DataGroupSelectModal
                    isOpen={true}
                    onClose={() => state?.setDataGroupSelectModal({ show: false, data: null })}
                    data={state?.dataGroupSelectModal.data}
                />
            )}

            {state?.deleteCommonModal.show && (
                <DeleteCommonModal
                    isOpen={true}
                    onClose={() => state?.setDeleteCommonModal({ show: false, data: null })}
                    data={state?.deleteCommonModal.data}
                />
            )}

            {state?.pdfPreviewModal.show && (
                <PdfPreviewModal
                    isOpen={true}
                    onClose={() => state?.setPdfPreviewModal({ show: false, data: null })}
                    data={state?.pdfPreviewModal.data}
                />
            )}

            {state?.contractRevisionModal.show && (
                <ContractRevisionModal
                    isOpen={true}
                    onClose={() => state?.setContractRevisionModal({ show: false, data: null })}
                    data={state?.contractRevisionModal.data}
                />
            )}

            {state?.tokenIlustrationModal.show && (
                <TokenIlustrationModal
                    isOpen={true}
                    onClose={() => state?.setTokenIlustrationModal({ show: false, data: null })}
                    data={state?.tokenIlustrationModal.data}
                />
            )}

            {state?.contractSignersSelectModal.show && (
                <ContractSignaturesModal
                    isOpen={true}
                    onClose={() => state?.setContractSignersSelectModal({ show: false, data: null })}
                    data={state?.contractSignersSelectModal.data}
                />
            )}

            {state?.newVaultFolderModal.show && (
                <NewVaultFolderModal
                    isOpen={true}
                    onClose={() => state?.setNewVaultFolderModal({ show: false, data: null })}
                    data={state?.newVaultFolderModal.data}
                />
            )}
            {state?.uploadVaultFileModal.show && (
                <UploadVaultDocumentModal
                    isOpen={true}
                    onClose={() => state?.setUploadVaultFileModal({ show: false, data: null })}
                    data={state?.uploadVaultFileModal.data}
                />
            )}
            {state?.newEditUserModal.show && (
                <NewEditUserModal
                    isOpen={true}
                    onClose={() => state?.setNewEditUserModal({ show: false, data: null })}
                    data={state?.newEditUserModal.data}
                />
            )}
            {state?.newEditUsersGroupModal.show && (
                <NewEditUsersGroupModal
                    isOpen={true}
                    onClose={() => state?.setNewEditUsersGroupModal({ show: false, data: null })}
                    data={state?.newEditUsersGroupModal.data}
                />
            )}
            {/* UI */}
            <LoaderModal isOpen={state?.loaderModal} onClose={() => state?.setLoaderModal(false)} data={null} />
            <KlausChat />
            {state?.confirmAlertModal.show && (
                <ConfirmAlertModal
                    isOpen={true}
                    onClose={() => state?.setConfirmAlertModal({ show: false, data: null })}
                    data={state?.confirmAlertModal.data}
                />
            )}

            {state?.infoCommonModal.show && (
                <InfoCommonModal
                    isOpen={true}
                    onClose={() => state?.setInfoCommonModal({ show: false, data: null })}
                    data={state?.infoCommonModal.data}
                />
            )}

            {state?.uploadContractAnnexModal.show && (
                <UploadContractAnnexModal
                    isOpen={true}
                    onClose={() => state?.setUploadContractAnnexModal({ show: false, data: null })}
                    data={state?.uploadContractAnnexModal.data}
                />
            )}

            {state?.contractBulkLoadModal.show && (
                <ContractBulkLoad
                    isOpen={true}
                    onClose={() => state?.setContractBulkLoadModal({ show: false, data: null })}
                    data={state?.contractBulkLoadModal.data}
                />
            )}

            {state?.simpleAlertModal.show && (
                <SimpleAlertModal
                    isOpen={state?.simpleAlertModal.show}
                    onClose={() => state?.setSimpleAlertModal({ show: false, data: null })}
                    data={state?.simpleAlertModal.data}
                />
            )}
            {state?.templateHeaderFooterModal.show && (
                <TemplateHeaderFooterModal
                    isOpen={true}
                    onClose={() => state?.setTemplateHeaderFooterModal({ show: false, data: null })}
                    data={state?.templateHeaderFooterModal.data}
                />
            )}
            {state?.videoMeetingModal.show && (
                <VideoMeetingModal
                    isOpen={true}
                    onClose={() => state?.setVideoMeetingModal({ show: false, data: null })}
                    data={state?.videoMeetingModal.data}
                />
            )}

            {state?.newTemplateFromDocxModal.show && (
                <TemplateFromDocxModal
                    isOpen={true}
                    onClose={() => state?.setNewTemplateFromDocxModal({ show: false, data: null })}
                    data={state?.newTemplateFromDocxModal.data}
                />
            )}

            {state?.faqModal.show && (
                <FAQModal isOpen={true} onClose={() => state?.setFaqModal({ show: false, data: null })} data={state?.faqModal.data} />
            )}

            {state?.uploadBiddingReqDocModal.show && (
                <UploadBiddingDocModal
                    isOpen={true}
                    onClose={() => state?.setUploadBiddingReqDocModal({ show: false, data: null })}
                    data={state?.uploadBiddingReqDocModal.data}
                />
            )}

            {state?.uploadBiddingReqAxCalcModal.show && (
                <UploadBiddingAxCalcModal
                    isOpen={true}
                    onClose={() => state?.setUploadBiddingReqAxCalcModal({ show: false, data: null })}
                    data={state?.uploadBiddingReqAxCalcModal.data}
                />
            )}

            {state.newBiddingModal.show && (
                <NewBiddingModal
                    isOpen={true}
                    onClose={() => state?.setNewBiddingModal({ show: false, data: null })}
                    data={state?.newBiddingModal.data}
                />
            )}

            {state?.biddingTypeModal.show && (
                <BiddingTypeModal
                    isOpen={true}
                    onClose={() => state?.setBiddingTypeModal({ show: false, data: null })}
                    dataPropsProps={state?.biddingTypeModal.data}
                />
            )}

            {state?.expedientModal.show && (
                <ExpedientModal
                    isOpen={true}
                    onClose={() => state?.setExpedientModal({ show: false, data: null })}
                    data={state?.expedientModal.data}
                />
            )}

            {state?.newExpedientModal.show && (
                <CreateExpedienteModal
                    isOpen={true}
                    onClose={() => state?.setNewExpedientModal({ show: false, data: null })}
                    data={state?.newExpedientModal.data}
                />
            )}

            {state?.handleBiddingSCMModal.show && (
                <HandleBiddingSCMModal
                    isOpen={true}
                    onClose={() => state?.setHandleBiddingSCMModal({ show: false, data: null })}
                    data={state?.handleBiddingSCMModal.data}
                />
            )}

            {state.addNewCompanyModal?.show && (
                <AddNewCompanyModal isOpen={true} onClose={() => state?.setAddNewCompanyModal({ show: false, data: null })} />
            )}

            {state?.createNewCompanyModal.show && (
                <CreateNewCompanyModal
                    isOpen={true}
                    onClose={() => state?.setCreateNewCompanyModal({ show: false, data: null })}
                    data={state?.createNewCompanyModal.data}
                />
            )}
            {state?.newEditRoleModal.show && (
                <NewEditRoleModal
                    isOpen={true}
                    onClose={() => state?.setNewEditRoleModal({ show: false, data: null })}
                    data={state?.newEditRoleModal.data}
                />
            )}

            {state?.biddingTypeModalUrlPowerBi.show && (
                <BiddingTypeUrlPowerBI
                    isOpen={true}
                    onClose={() => state?.setBiddingTypeModalUrlPowerBi({ show: false, data: null })}
                    data={state?.biddingTypeModalUrlPowerBi.data}
                />
            )}

            {state?.biddingPowerBiModal.show && (
                <ShowBiddingPowerBiModal
                    isOpen={true}
                    onClose={() => state?.setBiddingPowerBiModal({ show: false, data: null })}
                    data={state?.biddingPowerBiModal.data}
                />
            )}

            {state?.biddingProfileModal.show && (
                <BiddingProfileModal
                    isOpen={true}
                    onClose={() => state?.setBiddingProfileModal({ show: false, data: null })}
                    data={state?.biddingProfileModal.data}
                />
            )}

            {state?.biddingNotificationsModal.show && (
                <BiddingNotificationsModal
                    isOpen={true}
                    onClose={() => state?.setBiddingNotificationsModal({ show: false, data: null })}
                    data={state?.biddingNotificationsModal.data}
                />
            )}

            {state?.biddingTypeNotificationsModal.show && (
                <BiddingTypeNotificationsModal
                    isOpen={true}
                    onClose={() => state?.setBiddingTypeNotificationsModal({ show: false, data: null })}
                    data={state?.biddingTypeNotificationsModal.data}
                />
            )}

            {state?.restrictedActionModal.show && (
                <RestrictedActionModal
                    isOpen={true}
                    onClose={() => state?.setRestrictedActionModal({ show: false, data: null })}
                    data={state?.restrictedActionModal.data}
                />
            )}

            {state?.traceabilityCommonDrawer.show && (
                <TraceabilityCommonDrawer
                    isOpen={true}
                    onClose={() => state?.setTraceabilityCommonDrawer({ show: false, data: null })}
                    data={state?.traceabilityCommonDrawer.data}
                />
            )}

            {state?.showUsersOtpModal.show && (
                <ShowUsersOtpModal
                    isOpen={true}
                    onClose={() => state?.setShowUsersOtpModal({ show: false, data: null })}
                />
            )}

            {state?.showGeneradorStateModal.show && (
                <GeneradorStateModal
                    isOpen={true}
                    onClose={() => state?.setShowGeneradorStateModal({ show: false, data: null })}
                    data={state?.showGeneradorStateModal.data}

                />
            )}
            
        </>
    );
};

export default ModalsGroup;
