import React, { useState, useRef, useEffect, ChangeEvent, MouseEvent, KeyboardEvent } from 'react';
import { Button, makeStyles, Spinner, Text, tokens } from "@fluentui/react-components";
import { Box } from "@chakra-ui/react";
import { Send48Filled, Send48Regular, bundleIcon } from "@fluentui/react-icons";

const MAX_CHARS = 4000; // Puedes ajustar este valor según tus necesidades

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        borderRadius: '8px',
        background: tokens.colorNeutralBackground1
    },
    textarea: {
        backgroundColor: tokens.colorNeutralBackground1,
        color: tokens.colorNeutralForeground1,
        width: 'calc(100% - 5rem)',
        border: 'none',
        resize: 'none',
        padding: '16px 0px 16px 10px',
        fontSize: tokens.fontSizeBase300,
        fontFamily: tokens.fontFamilyMonospace,
        fontWeight: tokens.fontWeightRegular,
        marginLeft: '-10px',
        lineHeight: '1.5',
        '&:focus': {
            outline: 'none',
        },
        maxHeight: '200px',
    },
    controls: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
    },
    buttonBox: {
        display: 'flex',
        marginLeft: 'auto',
        marginRight: '2rem',
    },
    charCount: {
        fontFamily: tokens.fontFamilyNumeric,
        display: 'flex',
        bottom: '8px',
        position: 'absolute',
        fontSize: '12px',
    }
});

const Send = bundleIcon(Send48Filled, Send48Regular);

interface TypingBarProps {
    isAnswering: boolean;
    sendMessage: (message: string) => void;
}

export const TypingBar: React.FC<TypingBarProps> = ({ isAnswering, sendMessage }) => {
    const classes = useStyles();
    const [message, setMessage] = useState<string>('');
    const textareaRef = useRef<HTMLTextAreaElement | null>(null);

    useEffect(() => {
        adjustTextareaHeight();
    }, [message]);

    const adjustTextareaHeight = () => {
        if (textareaRef.current) {
            textareaRef.current.style.height = 'auto';
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
    };

    const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        const text = e.target.value;
        if (text.length <= MAX_CHARS) {
            setMessage(text);
        }
    };

    const handleSend = (e: MouseEvent<HTMLButtonElement> | KeyboardEvent<HTMLTextAreaElement>) => {
        e.preventDefault();
        if (message.trim().length > 0) {
            sendMessage(message.trim());
            setMessage('');
        }
    };

    // Maneja el evento de la tecla "Enter"
    const handleKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault(); // Evita que se añada una nueva línea
            handleSend(e); // Activa el botón de enviar
        }
    };

    return (
        <Box className={classes.root}>
            <Box className={classes.controls}>
                <Text className={classes.charCount}>
                    {message.trim().length}/{MAX_CHARS}
                </Text>
            </Box>
            <Box display={'flex'} flexDirection={'row'}>
                <textarea
                    disabled={isAnswering}
                    ref={textareaRef}
                    placeholder={isAnswering ? "Respondiendo..." : "Escribe aquí..."}
                    value={message}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown} // Captura la tecla "Enter"
                    className={classes.textarea}
                    rows={1}
                    maxLength={MAX_CHARS}
                />
                <Box className={classes.buttonBox}>
                    {isAnswering && <Spinner />}
                    {!isAnswering && (
                        <Button
                            appearance="transparent"
                            onClick={handleSend}
                            icon={<Send />}
                            size="large"
                            disabled={message.trim().length === 0 || isAnswering}
                        />
                    )}
                </Box>
            </Box>
        </Box>
    );
};
