import { useState, useEffect } from "react";
import MainLayout from "../components/layouts/MainLayout";
import ProfileBox from "../components/home/ProfileBox";
import ShorcutsList from "../components/home/ShorcutsList";
import ContractList from "../components/home/ContractList";
import StaristicsList from "../components/home/homeAmericaMovil/StaristicsList";
import ActivitiesList from "../components/home/homeAmericaMovil/ActivitiesList";
import PowerBIIFrame from "../components/home/homeAmericaMovil/PowerBIIFrame";
import ProfileBoxAm from "../components/home/homeAmericaMovil/ProfileBoxAm";
import ProfileBoxKlaus from "../components/home/homeKlaus/ProfileBoxKlaus";
import useComponetState from "src/store/components/componentState";
import useGlobalState from "src/store/globalState";
import BoxDashboard from "src/components/home/homeAmericaMovil/BoxDashBoard";
import BoxDashboardKlaus from "src/components/home/homeKlaus/BoxDashBoard";
import useAuthState from "src/store/authState";
import { useNavigate } from "react-router-dom";

const HomePage = () => {

    const loadedUiOptions = useComponetState((s) => s.loadUiOptions);
    const getAllTabsPermission = useComponetState((s) => s.getAllTabs);
    const getAllBtnAdmin = useComponetState((s) => s.getAllBtnAdmin);
    const getAllBtnProcess = useComponetState((s) => s.getAllBtnProcess);
    const getAllBtnTemplate = useComponetState((s) => s.getAllBtnTemplate);
    const setLoadUiOptions = useComponetState((s) => s.setLoadUiOptions);
    const additionalCompanyInfo = useGlobalState((s) => s.additionalCompanyInfo);
    const { timestampExp } = useAuthState()
    const checkSessionExpires = useAuthState( s => s.checkSessionExpires )
    const isSelectedChildCompany = useGlobalState( s => s.isSelectedChildCompany )
    const navigate = useNavigate()


    useEffect(() => {
        // Get user info
        if (!loadedUiOptions) {
            // Get user info
          //  getAllTabsPermission();
        //    getAllBtnAdmin();
            getAllBtnProcess();
            getAllBtnTemplate();
            // Actualiza el estado para indicar que ya se ha realizado la carga de UI
            setLoadUiOptions(true);
        }
    }, [ getAllBtnAdmin, getAllBtnProcess, getAllBtnTemplate, loadedUiOptions]);

    useEffect( () => {
        checkSessionExpires()
    }, [timestampExp])

    console.log("additionalCompanyInfo",additionalCompanyInfo)

    const renderDasboard = () => {
        if ( isSelectedChildCompany ) {
            switch (additionalCompanyInfo.dashBoardType) {
                case 'HomeGeneric':
                    return <HomeGenericPage />;
                case 'DashV2':
                    return <HomeAmericaMovilPage />;
                case 'DashKivit':
                    return <HomeKivitPage />;
                case 'Klaus':
                    return <KlausPage />
                default:
                    return <HomeGenericPage />; 
            }
        } else {
            navigate( "/group-selection" )
        }
    };

    return (
        <>
            <MainLayout>
                <main className="w-full p-6 pt-1">
                    <div className="w-full flex max-h-[25rem] mb-4">
                        <div className="w-full">
                        {renderDasboard()}
                        </div>
                    </div>
                </main>
            </MainLayout>
        </>
    );
};


const HomeGenericPage = () => {
    return (
        <>
            <ProfileBox />
            <ShorcutsList />
            <ContractList />
        </>
    );
}

const HomeAmericaMovilPage = () => {
    return (
        <>
            <ProfileBoxAm />
            <BoxDashboard />
        </>
    );
}

const KlausPage = () => {
    return (
        <>
            <ProfileBoxKlaus />
            <BoxDashboardKlaus />
        </>
    );
}

const HomeKivitPage = () => {
    return (
        <>
            <ProfileBoxAm />
            <BoxDashboard />
        </>
    );
}
export default HomePage;