import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const toggleChat = createAsyncThunk(
    "chat/toggleChat",
    async (_, thunkAPI) => {
        try {
            const currentState = thunkAPI.getState().chat;
            const newOpenState = !currentState.open;
            return newOpenState;
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);

export const selectFolder = createAsyncThunk(
    "chat/selectFolder",
    async (payload, thunkAPI) => {
        try {
            const folder = payload.folder;
            return folder;
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);

export const selectDocument = createAsyncThunk(
    "chat/selectDocument",
    async (payload, thunkAPI) => {
        try {
            const document = payload.document;
            return document;
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);

export const chatSlice = createSlice({
    name: "chat",
    initialState: {
        open: false,
        document: null,
        folder: null,
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(selectFolder.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(selectFolder.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.folder = action.payload; // Cambiado de state.vault a state.folder
            })
            .addCase(selectFolder.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload.error;
            })
            .addCase(selectDocument.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(selectDocument.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.document = action.payload;
            })
            .addCase(selectDocument.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload.error;
            })
            .addCase(toggleChat.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(toggleChat.fulfilled, (state, action) => {
                state.status = 'idle';
                state.open = action.payload;
            })
            .addCase(toggleChat.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload.error; // Añadido .error para consistencia
            });
    },
});

export default chatSlice.reducer;