import { createRoot } from 'react-dom/client';

class RestApi {
    constructor() {
        this.baseUrl = '/v1/';
        this.container = document.getElementById('root');
        let child = document.createElement('div');
        child.id = 'backdrop';
        this.div = this.container.appendChild(child);
        document.body.appendChild(this.div);
        this.backdropRoot = createRoot(this.div); // Create the root once
        this.backdropRef = null; // Referencia al componente Backdrop
        this.renderBackdrop(false); // Render the Backdrop initially
    }

    renderBackdrop(open) {
        /*this.backdropRef = (
            <Box
                position="fixed"
                top="0"
                left="0"
                width="100vw"
                height="100vh"
                bg="rgba(0, 0, 0, 0.5)"
                display="flex"
                alignItems="center"
                justifyContent="center"
                color="white"
                zIndex="overlay" // Similar a `theme.zIndex.drawer + 1` en MUI
            >
                <Spinner size="xl" color="inherit" />
            </Box>
        );
        this.backdropRoot.render(this.backdropRef); */// Use the created root to render
    }

    updateBackdrop(open) {
        this.renderBackdrop(open); // Update the Backdrop
    }

    loadHeaders = async () => {
        try {
            const token = await this.loadToken();
            this.headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICI5eU5NN2oxc0lhQ2xlY0ZHdmNQekROVlhZNUlIcmpfVExwWnQ4cnF2RnZVIn0.eyJleHAiOjE3MjY2MDk5MzYsImlhdCI6MTcyNjYwODEzNiwianRpIjoiMTE3MTNhZjEtYmRhOC00NGIzLWJjNTEtNjg0ZTg2ZjkyZjUyIiwiaXNzIjoiaHR0cHM6Ly9rZXljbG9ha3FhLmF4ZWxlcmF0dW0uY29tL2F1dGgvcmVhbG1zL3NlY3VyaXR5IiwiYXVkIjoiYWNjb3VudCIsInN1YiI6Ijk3MmE1ZWUwLTZiYTgtNDE4Ni1hOWYyLTQ5Njc5NjI0MzhlNiIsInR5cCI6IkJlYXJlciIsImF6cCI6Im1hdGVyaWFsaWRhZCIsInNlc3Npb25fc3RhdGUiOiJkNjlhMjE2MS0xYjMzLTQ2MGQtOTFhNC0wMDJhODI0MmRiY2QiLCJhY3IiOiIxIiwiYWxsb3dlZC1vcmlnaW5zIjpbImh0dHBzOi8va2l2aXRxYS5heGVsZXJhdHVtLmNvbS8iLCJodHRwczovL21hdGVyaWFsaWRhZHFhLmF4ZWxlcmF0dW0uY29tIiwiaHR0cHM6Ly9raXZpdHFhLmF4ZWxlcmF0dW0uY29tIl0sInJlYWxtX2FjY2VzcyI6eyJyb2xlcyI6WyJkZWZhdWx0LXJvbGVzLXNlY3VyaXR5Iiwib2ZmbGluZV9hY2Nlc3MiLCJ1bWFfYXV0aG9yaXphdGlvbiJdfSwicmVzb3VyY2VfYWNjZXNzIjp7ImFjY291bnQiOnsicm9sZXMiOlsibWFuYWdlLWFjY291bnQiLCJtYW5hZ2UtYWNjb3VudC1saW5rcyIsInZpZXctcHJvZmlsZSJdfX0sInNjb3BlIjoicHJvZmlsZSBlbWFpbCIsInNpZCI6ImQ2OWEyMTYxLTFiMzMtNDYwZC05MWE0LTAwMmE4MjQyZGJjZCIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJjb21wYW55R3JvdXBzIjpbIi82NmRiMmIxOWQ2NWQ1ZjNmYjc2ODU4NzUiXSwibmFtZSI6IkFkbWluIE5hbWUiLCJsYXN0UGFzc3dvcmRVcGRhdGUiOiIwNi8wOS8yMDI0IDE2OjE5OjAxIiwiYWNjb3VudF9hZGRyZXNzIjoiaGQtdTFqaWExcDhiMS1xNGdnc2tjZi0xIiwicHJlZmVycmVkX3VzZXJuYW1lIjoia2xhdXNfaW50ZXJub3FhQHlvcG1haWwuY29tIiwiZ2l2ZW5fbmFtZSI6IkFkbWluIE5hbWUiLCJlbWFpbCI6ImtsYXVzX2ludGVybm9xYUB5b3BtYWlsLmNvbSIsInVwZGF0ZWRQYXNzd29yZCI6InRydWUiLCJyZmMiOiJCQVNFODUwMjIyVjM0In0.OhoFQVY4GI-kJLFDSaJ0zZ0cdJK0ee7Ewl9vsItOoraW3AKf_O80bk2fqewYJteSXOB6xIyycfspLpgswmkq5dgFPATrAuF3usdhAP2aG4xkttcoYb-bcRupA2ubXqIjf0zVw79t8amcib5VKP3InrX8Qty3yNWDkSLkssBIXnWL6RkL8HXpeRTJve1eFxEnUm9lP9yWYSTbEAfSjkml5jOJ1TH8lH7Nu7DiitmBinIJySzIdJF4uIBJbcHx2z4_39XxE3s9xHyQY49rXHwamdG7MAo64gl1HQsjEtOU9dRjByc3_EkdJmq7SpRzwsa5afLcQ_DfMebHqaUfUb9M5A`
            }
            return this.headers;
        } catch (error) {
            this.headers = {
                "Accept": "*/*",
                'Content-Type': 'application/json',
            }
            return this.headers;
        }
    }

    loadMultipartHeaders = async () => {
        try {
            const token = await this.loadToken();
            this.headers = {
                'Authorization': `Bearer ${token}`
            }
            return this.headers;
        } catch (error) {
            this.headers = {
                'Content-Type': 'application/json'
            }
            return this.headers;
        }
    }

    loadToken = () => {
        return new Promise((resolve, reject) => {
            let intentos = 0;
            const buscarValor = () => {
                const authUser = JSON.parse(sessionStorage.getItem("authUser"));
                if (authUser.state.accessToken) {
                    resolve(authUser.state.accessToken);
                } else {
                    if (intentos < 1) {
                        intentos++;
                        console.log(`Intento ${intentos} de 5: No se encontró ningún valor en el localStorage, intentando de nuevo...`);
                        setTimeout(buscarValor, 1000); // Espera 1 segundo antes de intentar nuevamente
                    } else {
                        reject('No se encontró ningún valor en el localStorage después de 5 intentos');
                    }
                }
            };
            buscarValor();
        });
    };

    handleResponse = async (response) => {
        if (!response.ok) {
            this.updateBackdrop(false); // Actualizamos el estado de open
            const error = await response.json();
            throw new Error(error.message || 'Hubo un problema con la solicitud');
        }
        this.updateBackdrop(false); // Actualizamos el estado de open
        return response.json();
    };

    async get(url) {
        this.updateBackdrop(true); // Actualizamos el estado de open
        const headers = await this.loadHeaders();
        console.log("headers........", headers);
        const response = await this.fetchWithTimeout(`${this.baseUrl}${url}`, { method: 'GET', headers: { ...headers } });
        return this.handleResponse(response);
    }

    async post(url, data) {
        this.updateBackdrop(true); // Actualizamos el estado de open
        const headers = await this.loadHeaders();
        const response = await this.fetchWithTimeout(`${this.baseUrl}${url}`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: headers,
            redirect: 'follow'
        });
        return this.handleResponse(response);
    }

    async multipartPost(url, data) {
        this.updateBackdrop(true); // Actualizamos el estado de open
        const headers = await this.loadMultipartHeaders();
        const response = await fetch(`${this.baseUrl}${url}`, {
            method: 'POST',
            body: data,
            headers: headers,
        });
        return this.handleResponse(response);
    }

    async put(url, data) {
        this.updateBackdrop(true); // Actualizamos el estado de open
        const headers = await this.loadHeaders();
        const response = await fetch(`${this.baseUrl}${url}`, {
            method: 'PUT',
            body: JSON.stringify(data),
            headers: headers,
        });
        return this.handleResponse(response);
    }

    async delete(url) {
        this.updateBackdrop(true); // Actualizamos el estado de open
        const headers = await this.loadHeaders();
        const response = await fetch(`${this.baseUrl}${url}`, {
            method: 'DELETE',
            headers: headers,
        });
        return this.handleResponse(response);
    }

    async fetchWithTimeout(endpoint, options = {}, timeout = 50000) {
        const baseUrl = `${process.env.REACT_APP_API_AI_GATEWAY}`;
        const url = `${baseUrl}${endpoint}`;
        
        const controller = new AbortController();
        const { signal } = controller;
        const timeoutId = setTimeout(() => controller.abort(), timeout);
        try {
            const response = await fetch(url, { ...options, signal });
            clearTimeout(timeoutId);
            return response;
        } catch (error) {
            this.updateBackdrop(false);
            if (error.name === 'AbortError') {
                throw new Error('Fetch timeout');
            }
            throw error;
        } finally {
            clearTimeout(timeoutId);
        }
    }
    
}

export default RestApi;