import { createSlice } from '@reduxjs/toolkit'


export const leftMenuSlice = createSlice({
  name: 'leftMenu',
  initialState: {
    isOpen: false,
  },
  reducers: {
    toggleLeftMenu: (state) => {
      state.isOpen = !state.isOpen;
    },
  },
})

// Action creators are generated for each case reducer function
export const { toggleLeftMenu } = leftMenuSlice.actions

export default leftMenuSlice.reducer