import RestApi from "./restApi";

class ChatServiceIa {
  constructor() {
    this.api = new RestApi();
  }

  loadToken = () => {
    return new Promise((resolve, reject) => {
      let intentos = 0;
      const buscarValor = () => {
        const authUser = JSON.parse(sessionStorage.getItem("authUser"));
        if (authUser.state.accessToken) {
          resolve(authUser.state.accessToken);
        } else {
          if (intentos < 1) {
            intentos++;
            console.log(`Intento ${intentos} de 5: No se encontró ningún valor en el localStorage, intentando de nuevo...`);
            setTimeout(buscarValor, 1000); // Espera 1 segundo antes de intentar nuevamente
          } else {
            reject('No se encontró ningún valor en el localStorage después de 5 intentos');
          }
        }
      };
      buscarValor();
    });
  };


  streamChat(chat) {
    try {
      const authorizationToken =   this.loadToken()
      const chatString = encodeURIComponent(JSON.stringify(chat));
      const response = new EventSource(`/v1/ai/chat/stream?chat=${chatString}`, {
        withCredentials: true,
        authorizationHeader: 'Bearer ' + authorizationToken
        
      });


      return response;
    } catch (error) {
      throw error;
    }
  }


  async loadChat(chatId) {
    try {
      const response = await this.api.get(`ai/chat/${chatId}`);
      return response;
    } catch (error) {
      throw error;
    }
  }


  async getChatsByDocumentHash(hash){
     
    try{
      const response = await this.api.get(`ai/chat/document/hash/${hash}`);
      return response;
    }catch(error){
      throw error;

    }

  }

  async getChats(folderId) {
    try {
      const response = await this.api.get(`ai/chat/folder/${folderId}`);
      console.log("chats,,,,,,,,,", response);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async getAnswers(chatData) {
    try {
      const response = await this.api.post("ai/chat", chatData);
      return response;
    } catch (error) {
      throw error;
    }
  }

}

const chatServiceIa = new ChatServiceIa();

export default chatServiceIa;