import { Progress, Tooltip, useBoolean } from "@chakra-ui/react";
import { useMemo } from "react";
import { BiChevronDown } from "react-icons/bi";
import { IoEyeOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { Notification } from "../../../models/Notification.models";
import useNotificationsState from "../../../store/notificationsState";
import { getTimeAgo, sliceText } from "../../../lib/utils";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";
import React from "react";
import LinkButton from "../../common/buttons/LinkButton";

let interval = null;

const NotificationList = () => {
    const navigate = useNavigate();
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];

    const { getNotifications, markNotificationAsRead, notifications } = useNotificationsState();
    const [loading, setLoading] = useBoolean(false);
    const [notificationsShowedCount, setNotificationsShowedCount] = React.useState(10);

    const unreadNotifications = useMemo(() => {
        notifications.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
        return notifications.filter((notification) => !notification.read);
    }, [notifications]);

    //show notifications on a batch of 10 notifications
    const showedNotifications = useMemo(() => {
        return unreadNotifications.slice(0, notificationsShowedCount);
    }, [unreadNotifications, notificationsShowedCount]);

    function getNotificationsByInterval() {
        getNotifications();
        interval = setInterval(() => {
            getNotifications();
        }, 10000);
    }

    function handleMarkAsRead(e, notification) {
        e.stopPropagation();
        setLoading.on();
        markNotificationAsRead(notification).finally(setLoading.off);
    }

    function handleClick(notification: Notification) {
        switch (notification?.type) {
            case "SCM_solicitud_revision":
                if (notification.source[0]?.key === "url") {

                    if (notification.source[0].val.startsWith("/reviewId/")) {
                        const url = notification.source[0].val;
                        console.log("url",url)
                        const documentId = url.split("/reviewId/")[1]?.split("/")[0];
                        const customRoute = `/document-revision/${documentId}`;
                        console.log("customRoute", customRoute)
                        navigate(customRoute);  
                    }else{
                        const url = notification.source[0].val;
                        navigate(url.split("/dashboard")[1]);
                    }
              
                }
                break;
            case "SCM_solicitud_firma":
                if (notification.source[0]?.key === "url") {
                    window.open(notification.source[0].val, "_blank").focus();
                }
                break;
            case "SCM_revision_usuario":
                if (notification.source[0]?.key === "url") {
                    const url = notification.source[0].val;
                    navigate(url.replace("/dashboard/agreements", "/contracts"));
                }
                break;
            default:
                break;
        }
    }


    return (
        <div className="w-full flex items-center justify-center p-2">
        <div className="w-[330px] px-2 overflow-y-auto relative"
            style={{ height: "calc(100vh - 44vh)" }}>
            <div className="w-full flex justify-between items-center text-lg font-medium text-slate-100 border-b p-2 sticky top-0" style={{ backgroundColor: '#54A822' }}>
                <div>
                    {t("notifications")}(<span className="text-base">{unreadNotifications?.length}</span>)
                </div>
            </div>
            {loading ? (
                <Progress size="xs" isIndeterminate colorScheme="brand" />
            ) : (
                <div className="w-full h-1"></div>
            )}
    
            <div className="divide-y divide-gray-200">
                {showedNotifications?.map((noti) => (
                    <MemoNotificationItem
                        key={noti.id}
                        noti={noti}
                        t={t}
                        handleClick={handleClick}
                        handleMarkAsRead={handleMarkAsRead}
                    />
                ))}
            </div>
    
            {unreadNotifications?.length > notificationsShowedCount && (
                <div className="w-full p-4 flex justify-center">
                    <LinkButton
                        rightIcon={<BiChevronDown />}
                        onClick={() => setNotificationsShowedCount((prev) => prev + 10)}
                    >
                        Ver más
                    </LinkButton>
                </div>
            )}
        </div>
    </div>
    );
};

const NotificationItem = ({
    noti,
    t,
    handleClick,
    handleMarkAsRead,
}: {
    noti: Notification;
    t: (key: TranslationKeys) => string;
    handleClick: (noti: Notification) => void;
    handleMarkAsRead: (e, noti: Notification) => void;
}) => {
    return (
        <div
            key={noti.id}
            onClick={() => handleClick(noti)}
            className="w-full p-2 flex gap-2 items-center cursor-pointer hover:bg-brand-50"
        >
            <Tooltip label={noti.text} fontSize={"xs"} zIndex="toast">
                <div>
                    <div className="text-sm">{sliceText(noti.text, 62)}</div>
                    <div className="text-brand-500 text-xs ">{getTimeAgo(noti.createdAt)}</div>
                </div>
            </Tooltip>
            {!noti.read && (
                <Tooltip label={t("markAsRead")} fontSize={"xs"} zIndex="toast" openDelay={500}>
                    <button
                        className="p-2 hover:bg-slate-200 rounded-full text-gray-600 hover:text-brand-600"
                        onClick={(e) => handleMarkAsRead(e, noti)}
                    >
                        <IoEyeOutline size={24} color="inherit" />
                    </button>
                </Tooltip>
            )}
        </div>
    );
};

const MemoNotificationItem = React.memo(NotificationItem, (prevProps, nextProps) => {
    return prevProps.noti.id === nextProps.noti.id;
});

export default React.memo(NotificationList);
