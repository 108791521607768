import create  from 'zustand';

// Definimos el store usando zustand
interface ArrayStore {
  chatOpen: boolean;
  items: any; // Puedes cambiar el tipo según lo que quieras guardar
  addItem: (item: any) => void;
  removeItem: (item: string) => void;
  clearItems: () => void;
  changeChatState: () => void;
}

export const useChatHistory = create<ArrayStore>((set) => ({
  chatOpen: false,
  items: [],
  changeChatState: () => set((state) => {
    console.log(state.chatOpen);
    return { chatOpen: !state.chatOpen }}),
  addItem: (item) => set((state) => ({ items: item })),
  removeItem: (item) => set((state) => ({ items: state.items.filter((i) => i !== item) })),
  clearItems: () => set({ items: [] }), // Limpia el array
}));
