import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import CompanyService from 'src/components/common/modals/KlausChat/services/company_service';

// Thunk to load companies
export const loadCompanies = createAsyncThunk(
    "company/loadCompanies",
    async (_, thunkAPI) => {
        try {
            const companies = await CompanyService.getCompaniesOwner();
            return companies;
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);

// Thunk to select a company
export const selectCompany = createAsyncThunk(
    "company/selectCompany",
    async (payload, thunkAPI) => {
        try {
            let companyId = localStorage.getItem('companyId');
            if (!companyId) {
                localStorage.setItem('companyId', payload.companyId);
                companyId = payload.companyId;
            }
            return companyId;
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);

// Slice for company
export const companySlice = createSlice({
    name: "company",
    initialState: {
        company: {id:null},
        companies: {},
        status: 'idle',
        error: null
    },
    reducers: {
        // You can add synchronous reducers here if needed
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadCompanies.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(loadCompanies.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.companies = action.payload;
            })
            .addCase(loadCompanies.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload.error;
            })
            .addCase(selectCompany.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(selectCompany.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.company.id = action.payload;
            })
            .addCase(selectCompany.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload.error;
            });
    }
});

export default companySlice.reducer;