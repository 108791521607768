import RestApi from "./restApi";

class FolderService {
    constructor() {
        this.restApi = new RestApi();
    }

      async createFolder(folderData) {
        try {
            const response = await this.restApi.post("folders", folderData);
            return response;
        } catch (error) {
            throw new Error(error.message || 'Hubo un problema con la solicitud');
        }
    }

    async getSheetFolder(companyId){
        try{

            const response = await this.restApi.get(`folders/company/${companyId}/sheet`);
            return response;
    
        }catch (error){

            throw new Error(error.message || 'Hubo un problema con la solicitud');
        }
    }

    

    async updateFolder(folderId, folderData) {
        try {
            const response = await this.restApi.put(`folders/${folderId}`, folderData);
            return response;
        } catch (error) {
            throw new Error(error.message || 'Hubo un problema con la solicitud');
        }
    }

    async getMainFolder(companyId, page) {
        try {
            const response = await this.restApi.get(`folders/company/${companyId}/page/${page}`);
            return response;
        } catch (error) {
            throw new Error(error.message || 'Hubo un problema con la solicitud');
        }
    }

    async getFolder(folderId, companyId,page) {
        try {
            const response = await this.restApi.get(`folders/${folderId}/company/${companyId}/page/${page}`);
            return response;
        } catch (error) {
            throw new Error(error.message || 'Hubo un problema con la solicitud');
        }
    }

    async deleteFolder(folderId) {
        try {
            const response = await this.restApi.delete(`folders/${folderId}`);
            return response;
        } catch (error) {
            throw new Error(error.message || 'Hubo un problema con la solicitud');
        }
    }

    async downloadFolder(folderId) {
        try {
            const response = await this.restApi.get(`folders/download/${folderId}`);
            return response;
        } catch (error) {
            throw new Error(error.message || 'Hubo un problema con la solicitud');
        }
    }

    async moveFolder(folderId, destId) {
        try {
            const response = await this.restApi.get(`folders/${folderId}/to/${destId}`);
            return response;
        } catch (error) {
            throw new Error(error.message || 'Hubo un problema con la solicitud');
        }
    }

    async getFolderData(folderId, companyId) {
        try {
            const response = await this.restApi.get(`folders/data/${folderId}/company/${companyId}`);
            return response;
        } catch (error) {
            throw new Error(error.message || 'Hubo un problema con la solicitud');
        }
    }

    async deleteSecureFolder(folderId) {
        try {
            const response = await this.restApi.delete(`folders/secure/${folderId}`);
            return response;
        } catch (error) {
            throw new Error(error.message || 'Hubo un problema con la solicitud');
        }
    }

}

const folderService = new FolderService();

export default folderService;