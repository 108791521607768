import { createSlice } from '@reduxjs/toolkit'


export const drawerSlice = createSlice({
  name: 'drawer',
  initialState: {
    isOpen: false,
    menuIndex: null,
    menu: null
  },
  reducers: {
    toggleLeftMenu: (state) => {
      state.isOpen = !state.isOpen;
    },
    replaceMenu : (state,action) => {

      state.menu = action.payload

    },
  },
})

// Action creators are generated for each case reducer function
export const { toggleLeftMenu,replaceMenu } = drawerSlice.actions

export default drawerSlice.reducer