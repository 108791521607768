import create from "zustand";
import { devtools } from "zustand/middleware";
import {
    ConfirmAlertModalData,
    ContractBulkLoadModalData,
    ContractRevisionModalData,
    ContractSignersSelectModalData,
    DeleteCommonModalData,
    DeleteTemplateModalData,
    ExpedientModalData,
    HandleBiddingSCMModalData,
    ModalParameters,
    NewEditRoleModalData,
    NewEditUserModalData,
    NewEditUserGroupModalData,
    PdfPreviewModalData,
    SelectDataGroupModalData,
    SimpleAlertModalData,
    TemplateFromDocxModalData,
    TemplateHeaderFooterModalData,
    TokenIlustrationModalData,
    UploadBiddingReqDocModalData,
    UploadContractAnnexModalData,
    UploadBiddingReqAxCalcModalData,
} from "../models/Modals.models";
import { state } from "@formkit/drag-and-drop";

interface State {
    sagaChatKlaus: boolean;
    loaderModal: boolean;
    editUserModal: ModalParameters<any>;
    editCompanyModal: ModalParameters<any>;
    deleteTemplateModal: ModalParameters<DeleteTemplateModalData>;
    deleteDataGroupModal: ModalParameters<any>;
    deleteContractModal: ModalParameters<any>;
    newDataTypeModal: ModalParameters<any>;
    contractTypesModal: ModalParameters<any>;
    templateSelectModal: ModalParameters<any>;
    dataGroupSelectModal: ModalParameters<SelectDataGroupModalData>;
    deleteCommonModal: ModalParameters<DeleteCommonModalData>;
    pdfPreviewModal: ModalParameters<PdfPreviewModalData>;
    contractRevisionModal: ModalParameters<ContractRevisionModalData>;
    tokenIlustrationModal: ModalParameters<TokenIlustrationModalData>;
    confirmAlertModal: ModalParameters<ConfirmAlertModalData>;
    contractSignersSelectModal: ModalParameters<ContractSignersSelectModalData>;
    newVaultFolderModal: ModalParameters<any>;
    uploadVaultFileModal: ModalParameters<any>;
    newEditUserModal: ModalParameters<NewEditUserModalData>;
    newEditUsersGroupModal: ModalParameters<any>;
    uploadContractAnnexModal: ModalParameters<UploadContractAnnexModalData>;
    contractBulkLoadModal: ModalParameters<ContractBulkLoadModalData>;
    simpleAlertModal: ModalParameters<SimpleAlertModalData>;
    templateHeaderFooterModal: ModalParameters<TemplateHeaderFooterModalData>;
    videoMeetingModal: ModalParameters<any>;
    newTemplateFromDocxModal: ModalParameters<TemplateFromDocxModalData>;
    faqModal: ModalParameters<any>;
    uploadBiddingReqDocModal: ModalParameters<UploadBiddingReqDocModalData>;
    uploadBiddingReqAxCalcModal: ModalParameters<UploadBiddingReqAxCalcModalData>;
    newBiddingModal: ModalParameters<any>;
    biddingTypeModal: ModalParameters<any>;
    expedientModal: ModalParameters<ExpedientModalData>;
    newExpedientModal: ModalParameters<any>;
    handleBiddingSCMModal: ModalParameters<HandleBiddingSCMModalData>;
    addNewCompanyModal: ModalParameters<any>;
    createNewCompanyModal: ModalParameters<any>;
    newEditRoleModal: ModalParameters<NewEditRoleModalData>;
    biddingTypeModalUrlPowerBi: ModalParameters<any>;
    biddingPowerBiModal: ModalParameters<any>;
    biddingProfileModal: ModalParameters<any>;
    biddingNotificationsModal: ModalParameters<any>;
    biddingTypeNotificationsModal: ModalParameters<any>;
    biddingNotificationsConfigModal: ModalParameters<any>;
    infoCommonModal: ModalParameters<any>;
    restrictedActionModal: ModalParameters<any>;
    traceabilityCommonDrawer: ModalParameters<any>;
    showUsersOtpModal: ModalParameters<any>;
    showGeneradorStateModal : ModalParameters<any>;
    setSagaChatKlaus: (show: boolean) => any;
    setLoaderModal: (show: boolean) => any;
    setEditUserModal: (args: ModalParameters<any>) => any;
    setEditCompanyModal: (args: ModalParameters<any>) => any;
    setDeleteTemplateModal: (args: ModalParameters<DeleteTemplateModalData>) => any;
    setDeleteDataGroupModal: (args: ModalParameters<any>) => any;
    setDeleteContractModal: (args: ModalParameters<any>) => any;
    setNewDatatTypeModal: (args: ModalParameters<any>) => any;
    setContractTypesModal: (args: ModalParameters<any>) => any;
    setTemplateSelectModal: (args: ModalParameters<any>) => any;
    setDataGroupSelectModal: (args: ModalParameters<SelectDataGroupModalData>) => any;
    setDeleteCommonModal: (args: ModalParameters<DeleteCommonModalData>) => any;
    setPdfPreviewModal: (args: ModalParameters<PdfPreviewModalData>) => any;
    setContractRevisionModal: (args: ModalParameters<ContractRevisionModalData>) => any;
    setTokenIlustrationModal: (args: ModalParameters<TokenIlustrationModalData>) => any;
    setConfirmAlertModal: (args: ModalParameters<ConfirmAlertModalData>) => any;
    setContractSignersSelectModal: (args: ModalParameters<ContractSignersSelectModalData>) => any;
    setNewVaultFolderModal: (args: ModalParameters<any>) => any;
    setUploadVaultFileModal: (args: ModalParameters<any>) => any;
    setNewEditUserModal: (args: ModalParameters<NewEditUserModalData>) => any;
    setNewEditUsersGroupModal: (args: ModalParameters<any>) => any;
    setUploadContractAnnexModal: (args: ModalParameters<UploadContractAnnexModalData>) => any;
    setContractBulkLoadModal: (args: ModalParameters<ContractBulkLoadModalData>) => any;
    setSimpleAlertModal: (args: ModalParameters<SimpleAlertModalData>) => any;
    setTemplateHeaderFooterModal: (args: ModalParameters<TemplateHeaderFooterModalData>) => any;
    setVideoMeetingModal: (args: ModalParameters<any>) => any;
    setNewTemplateFromDocxModal: (args: ModalParameters<TemplateFromDocxModalData>) => any;
    setFaqModal: (args: ModalParameters<any>) => any;
    setUploadBiddingReqDocModal: (args: ModalParameters<UploadBiddingReqDocModalData>) => any;
    setUploadBiddingReqAxCalcModal: (args: ModalParameters<UploadBiddingReqAxCalcModalData>) => any;
    setNewBiddingModal: (args: ModalParameters<any>) => any;
    setBiddingTypeModal: (args: ModalParameters<any>) => any;
    setExpedientModal: (args: ModalParameters<ExpedientModalData>) => any;
    setNewExpedientModal: (args: ModalParameters<any>) => any;
    setHandleBiddingSCMModal: (args: ModalParameters<HandleBiddingSCMModalData>) => any;
    setAddNewCompanyModal: (args: ModalParameters<any>) => any;
    setCreateNewCompanyModal: (args: ModalParameters<any>) => any;
    setNewEditRoleModal: (args: ModalParameters<NewEditRoleModalData>) => any;
    setBiddingTypeModalUrlPowerBi: (args: ModalParameters<any>) => any;
    setBiddingPowerBiModal: (args: ModalParameters<any>) => any;
    setBiddingProfileModal: (args: ModalParameters<any>) => any;
    setBiddingNotificationsModal: (args: ModalParameters<any>) => any;
    setBiddingTypeNotificationsModal: (args: ModalParameters<any>) => any;
    setBiddingNotificationsConfigModal: (args: ModalParameters<any>) => any;
    setInfoCommonModal: (args: ModalParameters<any>) => any;
    setRestrictedActionModal: (args: ModalParameters<any>) => any;
    setTraceabilityCommonDrawer: (args: ModalParameters<any>) => any;
    setShowUsersOtpModal: (args: ModalParameters<any>) => any;
    setShowGeneradorStateModal: (args: ModalParameters<any>) => any;
    reset: () => void;

}

const initialState = {
    sagaChatKlaus: false,
    loaderModal: false,
    editUserModal: { show: false, data: null },
    editCompanyModal: { show: false, data: null },
    deleteTemplateModal: { show: false, data: null },
    deleteDataGroupModal: { show: false, data: null },
    deleteContractModal: { show: false, data: null },
    newDataTypeModal: { show: false, data: null },
    contractTypesModal: { show: false, data: null },
    templateSelectModal: { show: false, data: null },
    dataGroupSelectModal: { show: false, data: null },
    deleteCommonModal: { show: false, data: null },
    pdfPreviewModal: { show: false, data: null },
    contractRevisionModal: { show: false, data: null },
    tokenIlustrationModal: { show: false, data: null },
    confirmAlertModal: { show: false, data: null },
    contractSignersSelectModal: { show: false, data: null },
    newVaultFolderModal: { show: false, data: null },
    uploadVaultFileModal: { show: false, data: null },
    newEditUserModal: { show: false, data: null },
    newEditUsersGroupModal: { show: false, data: null },
    uploadContractAnnexModal: { show: false, data: null },
    contractBulkLoadModal: { show: false, data: null },
    simpleAlertModal: { show: false, data: null },
    templateHeaderFooterModal: { show: false, data: null },
    videoMeetingModal: { show: false, data: null },
    newTemplateFromDocxModal: { show: false, data: null },
    faqModal: { show: false, data: null },
    uploadBiddingReqDocModal: { show: false, data: null },
    uploadBiddingReqAxCalcModal: { show: false, data: null },
    newBiddingModal: { show: false, data: null },
    biddingTypeModal: { show: false, data: null },
    expedientModal: { show: false, data: null },
    newExpedientModal: { show: false, data: null },
    handleBiddingSCMModal: { show: false, data: null },
    addNewCompanyModal: { show: false, data: null },
    createNewCompanyModal: { show: false, data: null },
    newEditRoleModal: { show: false, data: null },
    biddingTypeModalUrlPowerBi: { show: false, data: null },
    biddingPowerBiModal: { show: false, data: null },
    biddingProfileModal: { show: false, data: null },
    biddingNotificationsModal: { show: false, data: null },
    biddingTypeNotificationsModal: { show: false, data: null },
    biddingNotificationsConfigModal: { show: false, data: null },
    infoCommonModal: { show: false, data: null },
    restrictedActionModal: { show: false, data: null },
    traceabilityCommonDrawer: { show: false, data: null },
    showUsersOtpModal: { show: false, data: null },
    showGeneradorStateModal : { show : false, data : null}
};


const useModalState = create<State>(
    devtools(
        // ------------
        (set) => ({
            sagaChatKlaus: false,
            loaderModal: false,
            editUserModal: { show: false, data: null },
            editCompanyModal: { show: false, data: null },
            deleteTemplateModal: { show: false, data: null },
            deleteDataGroupModal: { show: false, data: null },
            deleteContractModal: { show: false, data: null },
            newDataTypeModal: { show: false, data: null },
            contractTypesModal: { show: false, data: null },
            templateSelectModal: { show: false, data: null },
            dataGroupSelectModal: { show: false, data: null },
            deleteCommonModal: { show: false, data: null },
            pdfPreviewModal: { show: false, data: null },
            contractRevisionModal: { show: false, data: null },
            tokenIlustrationModal: { show: false, data: null },
            confirmAlertModal: { show: false, data: null },
            contractSignersSelectModal: { show: false, data: null },
            newVaultFolderModal: { show: false, data: null },
            uploadVaultFileModal: { show: false, data: null },
            newEditUserModal: { show: false, data: null },
            newEditUsersGroupModal: { show: false, data: null },
            uploadContractAnnexModal: { show: false, data: null },
            contractBulkLoadModal: { show: false, data: null },
            simpleAlertModal: { show: false, data: null },
            templateHeaderFooterModal: { show: false, data: null },
            videoMeetingModal: { show: false, data: null },
            newTemplateFromDocxModal: { show: false, data: null },
            faqModal: { show: false, data: null },
            uploadBiddingReqDocModal: { show: false, data: null },
            uploadBiddingReqAxCalcModal: { show: false, data: null },
            newBiddingModal: { show: false, data: null },
            biddingTypeModal: { show: false, data: null },
            expedientModal: { show: false, data: null },
            newExpedientModal: { show: false, data: null },
            handleBiddingSCMModal: { show: false, data: null },
            addNewCompanyModal: { show: false, data: null },
            createNewCompanyModal: { show: false, data: null },
            newEditRoleModal: { show: false, data: null },
            biddingTypeModalUrlPowerBi: { show: false, data: null },
            biddingPowerBiModal: { show: false, data: null },
            biddingProfileModal: { show: false, data: null },
            biddingNotificationsModal: { show: false, data: null },
            biddingTypeNotificationsModal: { show: false, data: null },
            biddingNotificationsConfigModal: { show: false, data: null },
            infoCommonModal: { show: false, data: null },
            restrictedActionModal: { show: false, data: null },
            traceabilityCommonDrawer: { show: false, data: null },
            showUsersOtpModal: { show: false, data: null }, 
            showGeneradorStateModal: { show: false, data: null },
            setSagaChatKlaus: (show) => set((state) => ({ sagaChatKlaus: show})),
            setLoaderModal: (show) => set((state) => ({ loaderModal: show })),
            setEditUserModal: ({ show, data }) => set((state) => ({ editUserModal: { show, data } })),
            setEditCompanyModal: ({ show, data }) => set((state) => ({ editCompanyModal: { show, data } })),
            setDeleteTemplateModal: ({ show, data }) => set((state) => ({ deleteTemplateModal: { show, data } })),
            setDeleteDataGroupModal: ({ show, data }) => set((state) => ({ deleteDataGroupModal: { show, data } })),
            setDeleteContractModal: ({ show, data }) => set((state) => ({ deleteContractModal: { show, data } })),
            setNewDatatTypeModal: ({ show, data }) => set((state) => ({ newDataTypeModal: { show, data } })),
            setContractTypesModal: ({ show, data }) => set((state) => ({ contractTypesModal: { show, data } })),
            setTemplateSelectModal: ({ show, data }) => set((state) => ({ templateSelectModal: { show, data } })),
            setDataGroupSelectModal: ({ show, data }) => set((state) => ({ dataGroupSelectModal: { show, data } })),
            setDeleteCommonModal: ({ show, data }) => set((state) => ({ deleteCommonModal: { show, data } })),
            setPdfPreviewModal: ({ show, data }) => set((state) => ({ pdfPreviewModal: { show, data } })),
            setContractRevisionModal: ({ show, data }) => set((state) => ({ contractRevisionModal: { show, data } })),
            setTokenIlustrationModal: ({ show, data }) => set((state) => ({ tokenIlustrationModal: { show, data } })),
            setConfirmAlertModal: ({ show, data }) => set((state) => ({ confirmAlertModal: { show, data } })),
            setInfoCommonModal: ({ show, data }) => set((state) => ({ infoCommonModal: { show, data } })),
            setContractSignersSelectModal: ({ show, data }) => set((state) => ({ contractSignersSelectModal: { show, data } })),
            setNewVaultFolderModal: ({ show, data }) => set((state) => ({ newVaultFolderModal: { show, data } })),
            setUploadVaultFileModal: ({ show, data }) => set((state) => ({ uploadVaultFileModal: { show, data } })),
            setNewEditUserModal: ({ show, data }) => set((state) => ({ newEditUserModal: { show, data } })),
            setNewEditUsersGroupModal: ({ show, data }) => set((state) => ({ newEditUsersGroupModal: { show, data } })),
            setUploadContractAnnexModal: ({ show, data }) => set((state) => ({ uploadContractAnnexModal: { show, data } })),
            setContractBulkLoadModal: ({ show, data }) => set((state) => ({ contractBulkLoadModal: { show, data } })),
            setSimpleAlertModal: ({ show, data }) => set((state) => ({ simpleAlertModal: { show, data } })),
            setTemplateHeaderFooterModal: ({ show, data }) => set((state) => ({ templateHeaderFooterModal: { show, data } })),
            setVideoMeetingModal: ({ show, data }) => set((state) => ({ videoMeetingModal: { show, data } })),
            setNewTemplateFromDocxModal: ({ show, data }) => set((state) => ({ newTemplateFromDocxModal: { show, data } })),
            setFaqModal: ({ show, data }) => set((state) => ({ faqModal: { show, data } })),
            setUploadBiddingReqDocModal: ({ show, data }) => set((state) => ({ uploadBiddingReqDocModal: { show, data } })),
            setUploadBiddingReqAxCalcModal: ({ show, data }) => set((state) => ({ uploadBiddingReqAxCalcModal: { show, data } })),
            setNewBiddingModal: ({ show, data }) => set((state) => ({ newBiddingModal: { show, data } })),
            setBiddingTypeModal: ({ show, data }) => set((state) => ({ biddingTypeModal: { show, data } })),
            setExpedientModal: ({ show, data }) => set((state) => ({ expedientModal: { show, data } })),
            setNewExpedientModal: ({ show, data }) => set((state) => ({ newExpedientModal: { show, data } })),
            setHandleBiddingSCMModal: ({ show, data }) => set((state) => ({ handleBiddingSCMModal: { show, data } })),
            setAddNewCompanyModal: ({ show, data }) => set((state) => ({ addNewCompanyModal: { show, data } })),
            setCreateNewCompanyModal: ({ show, data }) => set((state) => ({ createNewCompanyModal: { show, data } })),
            setNewEditRoleModal: ({ show, data }) => set((state) => ({ newEditRoleModal: { show, data } })),
            setBiddingTypeModalUrlPowerBi: ({ show, data }) => set((state) => ({ biddingTypeModalUrlPowerBi: { show, data } })),
            setBiddingPowerBiModal: ({ show, data }) => set((state) => ({ biddingPowerBiModal: { show, data } })),
            setBiddingProfileModal: ({ show, data }) => set((state) => ({ biddingProfileModal: { show, data } })),
            setBiddingNotificationsModal: ({ show, data }) => set((state) => ({ biddingNotificationsModal: { show, data } })),
            setBiddingTypeNotificationsModal: ({ show, data }) => set((state) => ({ biddingTypeNotificationsModal: { show, data } })),
            setBiddingNotificationsConfigModal: ({ show, data }) => set((state) => ({ biddingNotificationsModal: { show, data } })),
            setRestrictedActionModal: ({ show, data }) => set((state) => ({ restrictedActionModal: { show, data } })),
            setTraceabilityCommonDrawer: ({ show, data }) => set((state) => ({ traceabilityCommonDrawer: { show, data } })),
            setShowUsersOtpModal: ({ show, data }) => set((state) => ({ showUsersOtpModal: { show, data } })),
            setShowGeneradorStateModal : ({ show, data }) => set((state) => ({ showGeneradorStateModal: { show, data } })),
            reset: () => set((state) => ({ ...state, ...initialState })),
        }),
        // ------------
        { name: "ModalsState" }
    )
);

export default useModalState;

export const confirmAlert = {
    show: (data: ConfirmAlertModalData) => useModalState.getState().setConfirmAlertModal({ show: true, data }),
};
