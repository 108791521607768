import NotifcationList from "./NotifcationList";
import klaus_logo from "src/assets/images/klaus_logo.png";
import sigea from "src/assets/images/SIGEA.png";
import compliance from "src/assets/images/con_compliance.png";
import jmc from "src/assets/images/JM&C.png";
import BiddingAxRoadmap from "src/components/bidding/AxRoadmap/BiddingAxRoadmap";

const BoxDashBoard = () => {
    return (
        <div className="box-dashboard w-full flex flex-col items-start justify-between p-2 mb-2 overflow-hidden">
            <div className="flex w-full">
                <div className="h-full bg-gray-100 rounded-xl shadow-xl mr-4">
                    <NotifcationList />
                </div>
                <div className="flex-1" style={{ width: '75%' }}>
                    {/* Contenedor del BiddingAxRoadmap */}
                    <div className="bg-gray-100 rounded-xl shadow-xl h-full">
                        <BiddingAxRoadmap />
                    </div>
                </div>
            </div>
            
            <footer className="w-full flex justify-center items-center p-4">
                <img className="w-[9%] mx-3" src={klaus_logo} alt="Klaus logo" />
                <img className="w-[9%] mx-3" src={sigea} alt="SIGEA logo" />
                <p className="flex-1 text-brand-600 leading-8 text-center lg:block hidden" style={{ color: '#54A822', fontSize: '30px' }}>
                    {"Plataforma Integral de Cumplimiento ASG"}
                </p>
                <img className="w-[8%] mx-3" src={compliance} alt="Klaus logo" />
                <img className="w-[5%] mx-3" src={jmc} alt="SIGEA logo" />
            </footer>
        </div>
    );
};

export default BoxDashBoard;
