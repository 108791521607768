import create from "zustand";
import { devtools, persist } from "zustand/middleware";
import { UserAuth, AuthPolicyData } from "../models/User.models";
import { decodeToken, getDifferenceInMilliseconds } from "../lib/utils";
import useGlobalState from "./globalState";
import { resetStore } from "./storeHelpers";
import useModalState from "./modalsState";
import authService from "src/services/auth.service";
import { useModalStore } from "./modal.store";
import { useIntervalStore } from "./interval.store";
import { useChatHistory } from "./KlausChatState";

const oneHour = 1000 * 60 * 60;
const SESSION_DURATION = oneHour * 2;
let sessionInterval: any;
let userInfoTimeout: any;

interface State extends UserAuth {
    isLoggedOut: boolean;
    sessionWillBeExpired: boolean;
    authPolicyData: AuthPolicyData;
    tokenExpiresIn: number;
    setUserAuth: (args: UserAuth) => any;
    onLogin: (data: any) => Promise<any>;
    setAuthPolicyData: ( data: AuthPolicyData ) => void;
    setTokenExpiresIn: (time: number | ((prev: number) => number)) => void;
    checkSessionExpires: () => void
    changeCompanyId: ( companyId: string ) => void
    logout: () => any;
    reset: () => any;
}

const initialState: UserAuth & any = {
    accessToken: null,
    refreshToken: null,
    userName: null,
    userEmail: null,
    userId: null,
    companyId: null,
    expireDate: null,
    clientExpireDate: null,
    isLoggedOut: true,
    sessionWillBeExpired: false,
    updatedPassword: true,
    passwordPolicyList: null,
    tokenExpiresIn: null,
    timestampExp: null
};

const useAuthState = create<State>(
    devtools(
        // ------------
        persist(
            (set, get) => ({
                ...initialState,
                isLoggedOut: true,
                setUserAuth: (args) => set((state) => ({ ...args })),
                onLogin: (data) => {
                    return new Promise(( resolve ) => {
                        const decodedToken = decodeToken(data.access_token);
                        const expiresIn = data.expires_in;
                        set(() => ({
                            isLoggedOut: false,
                            accessToken: data.access_token,
                            refreshToken: data.refresh_token,
                            userName: decodedToken.name,
                            userEmail: decodedToken.email,
                            userId: decodedToken.sub,
                            companyId: decodedToken.companyGroups && decodedToken.companyGroups[0].substring(1),
                            expireDate: new Date(decodedToken?.exp * 1000),
                            clientExpireDate: new Date(Date.now() + SESSION_DURATION),
                            sessionWillBeExpired: false,
                            updatedPassword: JSON.parse( decodedToken.updatedPassword ) as boolean,
                            tokenExpiresIn : expiresIn,
                            timestampExp: decodedToken.exp
                        }));

                        const { userId, companyId, accessToken, refreshToken } = get()

                        resolve({ userId, companyId, accessToken, refreshToken })

                        userInfoTimeout = setTimeout(() => {
                            // Verificar si el usuario todavía está autenticado antes de llamar a getUserInfo
                            if (!get().isLoggedOut) {
                                useGlobalState.getState().getUserInfo(decodedToken.sub, false);
                            }
    
                            setTimeout( () => {
                                if ( !useGlobalState.getState().userInfo ) {
                                    useGlobalState.getState().getUserInfo(decodedToken.sub, false);
    
                                    setTimeout( () => {
                                        if ( !useGlobalState.getState().userInfo ) {
                                            useGlobalState.getState().getUserInfo(decodedToken.sub, false)
                                        }
                                    }, 500)
                                }
                            }, 500)
                        }, 500);
                    })
                
                    // sessionInterval = setInterval(() => {
                    //     const isExpired = get().clientExpireDate < new Date();
                    //     if (isExpired) {
                    //         set({ sessionWillBeExpired: true });
                    //         setTimeout(() => {
                    //             // Verificar si el usuario todavía está autenticado antes de realizar el logout
                    //             if (!get().isLoggedOut) {
                    //                 get().logout();
                    //                 authService.sessionLogout();
                    //                 clearInterval(sessionInterval);
                    //                 useModalState.getState().setSimpleAlertModal({
                    //                     show: true,
                    //                     data: { text1: "warning", text2: "sessionExpired", whitTranslation: true },
                    //                 });
                    //             }
                    //         }, 2000);
                    //     }
                    // }, 60000);
                },
                logout: () => {
                    clearTimeout(userInfoTimeout);
                    clearInterval( sessionInterval );
                    authService.sessionLogout();
                    sessionStorage.removeItem( 'notificationSize' );
                    sessionStorage.clear();
                    localStorage.clear();
                    resetStore();
                },
                setAuthPolicyData: ( data: AuthPolicyData ) => {
                    sessionStorage.setItem( 'authPolicyData', JSON.stringify( data ) );
                    set({ authPolicyData: data });
                },  
                setTokenExpiresIn: (time) => {
                    set((state) => ({
                      tokenExpiresIn: typeof time === 'function' ? (time as (prev: number) => number)(state.tokenExpiresIn) : time,
                    }));
                  },     

                checkSessionExpires: () => {
                    console.log( 'checkSessionExpires' )
                    useIntervalStore.getState().clearInterval( 'sessionInterval' )

                    const sessionInterval = setInterval( () => {
                        const expiresDate = new Date(( get().timestampExp || 0 ) * 1000 )
                        const minutesToSubtract = 5
                        const millisecondsToSubtract = minutesToSubtract * 60 * 1000
                        const newDateInMilliseconds = expiresDate.getTime() - millisecondsToSubtract
                        const diffDate = new Date( newDateInMilliseconds )
                        const currentDate = new Date()

                        if ( currentDate > diffDate ) {
                            console.log( 'updateSession' )
                            useIntervalStore.getState().clearInterval( 'sessionInterval' )

                            authService.updateSession( get().refreshToken ).then(
                                response => {
                                    if ( response?.data?.access_token ) {
                                        get().onLogin({
                                            access_token: response.data.access_token,
                                            refresh_token: response.data.refresh_token,
                                            expires_in: response.data.expires_in,
                                            refresh_expires_in: response.data.refresh_expires_in
                                        })

                                        console.log( 'checkSessionExpires reLaunch' )
                                        get().checkSessionExpires()
                                    } 
                                }
                            )
                        }

                    }, 10000 )

                    useIntervalStore.getState().addInterval(
                        'sessionInterval', sessionInterval
                    )

                },
                // checkSessionExpires: () => {
                //     const expiresDate = new Date(( get().timestampExp || 0 ) * 1000 )
                //     const diffMill = getDifferenceInMilliseconds( new Date(), expiresDate )
                //     const intervalTime = diffMill - ( diffMill / 2 )

                //     const sessionInterval = setInterval( () => {
                //         useIntervalStore.getState().clearInterval( 'sessionInterval' )
                //         // useModalStore.getState().openModal( 'SessionExpireNotifier' )
                //         authService.updateSession( get().refreshToken ).then(
                //             response => {
                //                 if ( response?.data?.access_token ) {
                //                     get().onLogin({
                //                         access_token: response.data.access_token,
                //                         refresh_token: response.data.refresh_token,
                //                         expires_in: response.data.expires_in,
                //                         refresh_expires_in: response.data.refresh_expires_in
                //                     })
                //                 } 
                //             }
                //         )

                //     }, intervalTime )

                //     useIntervalStore.getState().addInterval(
                //         'sessionInterval', sessionInterval
                //     )
                // },
                changeCompanyId: ( companyId: string ) => set({ companyId }),

                reset: () => set((state) => ({ ...state, ...initialState })),
            }),
            { name: "authUser", getStorage: () => sessionStorage }
        ), 
        // ------------
        { name: "AuthState" }
    )
);

export default useAuthState;
